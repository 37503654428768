import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import NavItem from 'react-bootstrap/NavItem'
import NavLink from 'react-bootstrap/NavLink'
import styles from './TopBar.module.scss'
import iconLogo from './icon-logo.svg'
import iconFolder from './icon-folder.svg'
import { AuthContext } from '../../shared/provider/AuthProvider '
import Backdrop from '../LogIn/loadingBackdrop'

class TopBar extends Component {
    constructor(props) {
        super(props)
        this.onLocaleChanged = this.onLocaleChanged.bind(this)
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            loading: false 
        };
    }
    onLocaleChanged(e) {
        this.props.onLocaleChanged(e.target.dataset.value)
    }
    handleLabStart() {
        let view = this.props.views.labStart
        if (!this.props.hasBeenSaved) {
            view = this.props.views.lab
        }
        this.props.onChange(view)
    }
    handleLogout() {
        this.setState({ loading: true });
        const { updateAuthContext } = this.context;
            sessionStorage.clear();
            updateAuthContext(null, null);
            window.location.href = process.env.REACT_APP_API_BASE_URL_SVEN;
      }
    render() {
        const { loading } = this.state;
        if (loading) {
            return <Backdrop />;
          }
        return (
            <div className={styles.container}>  
                
                <div className={styles.top}>
                    <Navbar expand="lg" className={styles.navbar}>
                        <Navbar.Brand className={styles.logo} onClick={() => this.props.onChange(this.props.views.start)} href="">
                            <img alt="" src={iconLogo} className="d-inline-block align-top" />
                        </Navbar.Brand>
                        <Navbar id="basic-navbar-nav" className={styles.menu}>
                            <Nav className="mr-auto">
                                <Nav.Link className={`${styles.menuItemMargin} ${(this.props.currentView === this.props.views.lab || this.props.currentView === this.props.views.labStart) ? styles.active : null}`} onClick={() => this.handleLabStart()} href="">{intl.get('mainNav.lab')}</Nav.Link>
                                <Nav.Link className={`${styles.menuItemMargin} ${this.props.currentView === this.props.views.facts ? styles.active : null}`} onClick={() => this.props.onChange(this.props.views.facts)} href="">{intl.get('mainNav.guides')}</Nav.Link>
                                <Nav.Link className={`${styles.menuItemMargin} ${this.props.currentView === this.props.views.reports ? styles.active : null}`} onClick={() => this.props.onChange(this.props.views.reports)} href="">
                                    <img alt={intl.get('mainNav.reports')} src={iconFolder} className={styles.menuIcon}  />
                                </Nav.Link>
                                <Nav.Link className={`${styles.menuItemMargin}`} onClick={this.handleLogout} href="">{intl.get('mainNav.logout')}</Nav.Link>
                                <Dropdown as={NavItem}>
                                    <Dropdown.Toggle as={NavLink}>{this.props.currentLocale === 'sv-SE' ? "Sv" : "En"}</Dropdown.Toggle>
                                    <Dropdown.Menu className={styles.dropdown}>
                                        <Dropdown.Item onClick={(e) => this.onLocaleChanged(e)} data-value="sv-SE">
                                            Svenska
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => this.onLocaleChanged(e)} data-value="en-US">
                                            English
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Nav>
                        </Navbar>
                    </Navbar>
                </div>
                
            </div>
        )
    }
}
TopBar.contextType = AuthContext;

export default TopBar