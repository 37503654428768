import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Button from '../Lab/Button'
import Radiobutton from '../Input/Radiobutton'
import styles from './SaveReport.module.scss'
import MiddlewareService from '../../axios/service/MiddlewareService'

class SaveReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            reports: [],
            sessions: [],
            replaceReportIndex: -1,
        }

        this.currentSessionIndex = 0
        this.currentCache = null

        this.onSaveReport = this.onSaveReport.bind(this)
        this.onSaveSettingChange = this.onSaveSettingChange.bind(this)
        this.resetReplaceReportIndex = this.resetReplaceReportIndex.bind(this)
        
    }

    getReports() {

    }

    async onSaveReport(event) {

        event.preventDefault()

        let report = {}
        report.reportName = ""
        const date = new Date()
        const dateString = intl.get('general.date', {
            date: date
        }) + ' ' + intl.get('general.time', {
            time: date
        })

        report.date = dateString
        report.data = this.currentCache

        let currentSessionIndex = 0
        let sessions = []
        let session = {}
        session.reports = []
        let reports = []
        if (this.state.sessions.length > 0 
            && this.currentSessionIndex >=0 
            && this.currentSessionIndex < this.state.sessions.length) {
                sessions = this.state.sessions
                session = this.state.sessions[this.currentSessionIndex]
                reports = this.state.sessions[this.currentSessionIndex].reports
        }
        
        if (session.reports) {
            if (this.state.replaceReportIndex >= 0) {
                const oldReport = session.reports[this.state.replaceReportIndex]
                report.reportName = oldReport.reportName
                session.reports[this.state.replaceReportIndex] = report
            }
            else {
                report.reportName = this.inputReportNameEl.value
                report.id = new Date().getTime()
                session.reports.push(report)
            }
            sessions[this.currentSessionIndex] = session

            const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
            sessions[0].userEmail = currentUser.email; 

            // Send sessions to DB 
            await MiddlewareService.createSession(sessions)
            .then(response => {
                console.log('Session sparad:', sessions ); 
                console.log('Response',response)
                if(response === true)
                    {
                        localStorage.setItem('sessions', JSON.stringify(sessions))
                            this.setState({
                                sessions: sessions
                            })
                    }
                    else
                    {
                        console.error('Error when saving session in localhost'); 
                    }
            })
            .catch(error => {
                console.error('Error when saving session:', error); 
            });
        }

        
        if (localStorage.getItem('currentCache')) {
            this.currentCache.hasBeenSaved = true
            localStorage.setItem('currentCache', JSON.stringify(this.currentCache))
        }

        this.props.setHasBeenSaved(true)

        this.props.onViewChanged(this.props.views.reports)

    }

    onSaveSettingChange(el) {
        this.setState({
            replaceReportIndex: el.target.value
        })
    }

    resetReplaceReportIndex() {
        this.setState({
            replaceReportIndex: -1
        })
    }

    setInputStatus() {
        if (this.state.replaceReportIndex === -1) {
            this.inputReportNameEl.focus()
        }
        else {
            this.inputReportNameEl.blur()
        }
    }

    

    componentDidMount() {

    

        let sessions = []
        localStorage.getItem('sessions') && (sessions = JSON.parse(localStorage.getItem('sessions')))
        this.setState({
            sessions: sessions
        })

        let currentSessionIndex = 0
        localStorage.getItem('currentSessionIndex') && (currentSessionIndex = JSON.parse(localStorage.getItem('currentSessionIndex')))
        if (currentSessionIndex < 0) {
            currentSessionIndex = 0
        }
        else if (sessions.length > 0 && currentSessionIndex >= sessions.length) {
            currentSessionIndex = sessions.length-1
        }
        this.currentSessionIndex = currentSessionIndex
        
        if (localStorage.getItem('currentCache')) {
            this.currentCache = JSON.parse(localStorage.getItem('currentCache'))
        }

         this.setInputStatus()
        
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.state.replaceReportIndex === -1) {
            this.inputReportNameEl.focus()
        }
        else {
            this.inputReportNameEl.blur()
        }
    }

    render() {

        
        let reportsEl = []

        if (this.state.sessions[this.currentSessionIndex]) {
            const reports = this.state.sessions[this.currentSessionIndex].reports
            for (let i=reports.length-1; i>=0; i--) {    
                let key = i.toString() + reports[i].reportName
                if (reports[i].id) {
                    key = reports[i].id
                }
                if (reports[i].data) { // Don't show old local storage report format.
                    reportsEl.push(
                        <div className={`${styles.radioWrapper} ${styles.background}`} key={key}>
                            <Radiobutton
                                key={key}
                                name="reportIndex"
                                value={i}
                                checked={i === this.state.replaceReportIndex}
                                onChange={this.onSaveSettingChange}
                                label={reports[i].reportName}
                                fullContainerHeight={true}
                            />
                        </div>
                    )
                }
            }
        }



        return (

            <div className={styles.container}>

                 
                <h2>{intl.get('report.saveHeader')}</h2>
           
                <p>{intl.get('report.saveDescription')}</p>

                <form onSubmit={this.onSaveReport}>

                <h4>{intl.get('report.saveNewHeader')}</h4>

                <div className={styles.radioContainer}>

                    <div className={`${styles.radioWrapper}`}>
                        <Radiobutton
                            name="reportIndex"
                            value={-1}
                            checked={-1 === this.state.replaceReportIndex}
                            onChange={this.onSaveSettingChange}
                            label={"new filename"}
                            hideLabel={true}
                            fullContainerHeight={true}
                        />
                    </div>
                    <input type="text" name="lname" id="lname" 
                        className={styles.inputFilename}
                        ref={ref => this.inputReportNameEl = ref} 
                        onFocus={this.resetReplaceReportIndex}
                    />

                </div>

                <h4>{intl.get('report.saveReplaceHeader')}</h4>
                <div className={styles.radioContainer}>
                    {reportsEl}
                </div>

                <Button
                    type="submit"
                    label={intl.get('general.save')}
                    rounded={true}
                    strongBorders={true}
                    value={0}
                />
                
                </form>

            </div>

        )

    }

}

export default SaveReport