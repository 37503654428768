import React from 'react'
import styles from './Button.module.scss'

class Button extends React.Component {

    render() {
        return (
            <button 
                //disabled="disabled"
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick(this.props.value)
                    }
                }}
                type={this.props.type ? this.props.type : "button"}
                value={this.props.value}
                disabled={this.props.disabled}
                className={`${styles.container} 
                    ${this.props.icon && styles.hasIcon}
                    ${this.props.iconFirst && styles.iconFirst}
                    ${this.props.label && styles.hasLabel}
                    ${this.props.square && styles.isSquare}
                    ${this.props.circular && styles.isCircular}
                    ${this.props.rounded && styles.isRounded}
                    ${this.props.borders && styles.hasBorders}
                    ${this.props.strongBorders && styles.hasStrongBorders}
                    ${this.props.doubleBorders && styles.hasDoubleBorders}
                    ${this.props.active && styles.active}
                    ${this.props.dashed && styles.isDashed}
                    ${this.props.dotted && styles.isDotted}
                    ${this.props.linkStyle && styles.linkStyle}
                    ${this.props.disabled && styles.disabled}
                    ${this.props.className}
                    `}>
                        {this.props.iconFirst ?
                            <>
                                {this.props.icon}
                                <span>{this.props.label}</span>
                            </>
                        :
                            <>
                                <span>{this.props.label}</span>
                                {this.props.icon}  
                            </>
                        }
                        

            </button> 
        )
    }
}

export default Button
