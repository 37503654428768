import React, {Component} from 'react'
import RangeSlider from 'react-rangeslider'
import './RangeSlider.css'
import NumberEdit from '../Lab/NumberEdit'
import helpers from '../../utils/helpers'
import styles from './Slider.module.scss'

class Slider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            inputValue: helpers.getNumberString(this.props.value, 2)
        }
        this.inputSliderTouchStart = this.inputSliderTouchStart.bind(this)
        this.inputSliderTouchEnd = this.inputSliderTouchEnd.bind(this)
        this.inputSliderChange = this.inputSliderChange.bind(this)
        this.inputTextChange = this.inputTextChange.bind(this)
        this.inputTextBlur = this.inputTextBlur.bind(this)
        this.onFocusInput = this.onFocusInput.bind(this)
    }

    inputSliderTouchStart() {
        console.log("touch start")
        this.props.onSliderTouchStart()
    }

    inputSliderTouchEnd() {
        this.props.onSliderTouchEnd()
    }

    inputSliderChange(value) {
        this.props.onSliderChange(parseFloat(value))
        this.setState({
            inputValue: helpers.getNumberString(parseFloat(value), 2)
        })
    }

    inputTextChange(valueStr) {
        
        let value = valueStr
        value = value.replace(',', '.')

        if (isNaN(value)) {
            return
        }
        if (value.length === 0) {
            value = this.props.min
        }
        if (value < this.props.min || value > this.props.max) {
            return
        }
        
        this.props.onSliderChange(value)
        this.setState({
            inputValue: valueStr
        })
    }

    inputTextBlur(e) {
        this.setState({
            inputValue: helpers.getNumberString(this.props.value, 2)
        })
    }

    onFocusInput(e) {
        this.inputTextRef.select()
    }

    render() {

        let inputMode = "text"
        this.props.inputMode && (inputMode = this.props.inputMode)
        return (
            <div className={styles.container}>
                <div className={styles.top}>
                    <div className={styles.label}>
                        <h4>{this.props.label}</h4>
                    </div>
                    <div className={styles.inputtextContainer}>
                        {/* <input ref={(ref) => this.inputTextRef = ref} disabled={this.props.disabled} inputMode={inputMode}  value={this.state.inputValue} onFocus={(e) => this.onFocusInput(e)} onChange={this.inputTextChange} onBlur={this.inputTextBlur} className={styles.inputtext} />
                        <span>{intl.get('general.meterTextOnly')}</span> */}
                         <NumberEdit 
                            value={this.state.inputValue}
                            id={0}
                            onChange={this.inputTextChange}
                            align={"right"}
                        />
                    </div>
                </div>
                
                <RangeSlider
                        orientation="horizontal"
                        onChange={this.inputSliderChange}
                        min={this.props.min} 
                        max={this.props.max} 
                        step={this.props.step}
                        value={this.props.value}
                        onChangeStart={this.inputSliderTouchStart}
                        onChangeComplete={this.inputSliderTouchEnd}
                    />
                
            </div>
        )
    }
}

export default Slider