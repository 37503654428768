import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Modal from 'react-bootstrap/Modal'
import wallMaterials from '../../data/wallMaterials'
import ceilingMaterials from '../../data/wallMaterials'
import floorMaterials from '../../data/wallMaterials'
import LightPDFGenerator from './PDF/LightPDFGenerator'
import Button from '../Lab/Button'
import FieldEdit from '../Lab/FieldEdit'
import iconSave from '../Lab/icon-save.svg'
import iconBack from '../Lab/icon-back.svg'
import iconPdf from '../Lab/icon-pdf.svg'
import iconErase from '../Lab/icon-erase.svg'
import iconClose from '../Lab/icon-close.svg'
import styles from './Reports.module.scss'
import MiddlewareService from '../../axios/service/MiddlewareService'

class Reports extends Component {

    constructor(props) {
        super(props)

       
        this.state = {
            reports: [],
            sessions: [],
            replaceReportIndex: -1,
            showModalDelete: false,
            showModalReset: false,
        }

        this.currentSessionIndex = 0
        this.currentCache = null

        this.onReportChange = this.onReportChange.bind(this)
        this.onReportDelete = this.onReportDelete.bind(this)
        this.resetToDefaults = this.resetToDefaults.bind(this)

        this.LightPDFGenerator = new LightPDFGenerator()
        
    }

    generatePdf(event, index) {

        const sessions = this.state.sessions
        const report = sessions[this.currentSessionIndex].reports[index]

        this.LightPDFGenerator.setParams(
            report.data.absorbantRooms, 
            report.data.roomWidth,
            report.data.roomHeight,
            report.data.roomDepth,
            ceilingMaterials[report.data.ceilingMaterialIndex],
            wallMaterials[report.data.wallMaterialIndex],
            floorMaterials[report.data.floorMaterialIndex]
        )
        this.LightPDFGenerator.start()
    }

    onReportChange(value, index) {
        
        const sessions = this.state.sessions
        const report = sessions[this.currentSessionIndex].reports[index]
        report.reportName = value

        sessions[this.currentSessionIndex].reports[index] = report

        this.setState({
            sessions: sessions
        })

        this.reportToDeleteIndex = 0

    }

    onShowModalDelete(event, index) {
        this.setState({
            showModalDelete: true,
        })
        this.reportToDeleteIndex = index
    }

    onCloseModalDelete() {
        this.setState({
            showModalDelete: false
        })
    }

    onReportDelete = async (event, index) => {
        this.onCloseModalDelete();
        const sessions = this.state.sessions;
        let reports = sessions[this.currentSessionIndex].reports;
        let report = reports.splice(this.reportToDeleteIndex, 1);
    
        const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        const userEmail = currentUser.email; 
        
        // Call DB to also delete the report from DB
        try {
            await MiddlewareService.deleteSession(userEmail, report[0].id).then((success) => {
            if(success)
                {
                    const sessions = this.state.sessions;
                    let reports = sessions[this.currentSessionIndex].reports;
                    sessions[this.currentSessionIndex].reports = reports;
                    this.setState({ sessions: sessions });
                }
            } );
        } catch (error) {
            console.error("Error deleting session from database:", error);
        }
    }
    
    onLoadReportData(event, index) {

    }

    updateLocalStorageSessions() {
        localStorage.setItem('sessions', JSON.stringify(this.state.sessions))
    }

    updateLocalStorageCache(event, index) {
        
        const sessions = this.state.sessions
        const report = sessions[this.currentSessionIndex].reports[index]
        
        try {
            let currentCache = {
                'roomWidth': report.data.roomWidth,
                'roomDepth': report.data.roomDepth,
                'roomHeight': report.data.roomHeight,
                'ceilingMaterialIndex': report.data.ceilingMaterialIndex,
                'wallMaterialIndex': report.data.wallMaterialIndex,
                'floorMaterialIndex': report.data.floorMaterialIndex,
                'absorbantRooms': report.data.absorbantRooms,
            }
            localStorage.setItem('currentCache', JSON.stringify(currentCache))
            this.props.setHasBeenSaved(false)
            this.props.restoreFromCache()
            this.props.setLeftmostAbsorbantRoom(0)
            this.props.onViewChanged(this.props.views.lab)
            
        } catch(error) {
            this.props.resetToDefaults()
        }
        
    }

    handleReset() {
        
        if (this.props.hasBeenSaved) {
            this.resetToDefaults()
        }
        else {
            this.onShowModalReset()
        }
    
    }

    onShowModalReset() {
        this.setState({
            showModalReset: true
        })
    }

    onCloseModalReset() {
        this.setState({
            showModalReset: false
        })
    }
    onSaveAs() {
        this.onCloseModalReset()
        this.props.onViewChanged(this.props.views.saveReport)
    }
    resetToDefaults() {
        this.onCloseModalReset()
        this.props.resetToDefaults()
        this.props.onViewChanged(this.props.views.labStart)
    }

    handleBackToActive() {
        this.props.setHasBeenSaved(false)
        this.props.onViewChanged(this.props.views.lab)
    }

    componentDidMount() {
        let sessions = []
        localStorage.getItem('sessions') && (sessions = JSON.parse(localStorage.getItem('sessions')))

        this.setState({
            sessions: sessions
        })

        let currentSessionIndex = 0
        localStorage.getItem('currentSessionIndex') && (currentSessionIndex = JSON.parse(localStorage.getItem('currentSessionIndex')))
        if (currentSessionIndex < 0) {
            currentSessionIndex = 0
        }
        else if (sessions.length > 0 && currentSessionIndex >= sessions.length) {
            currentSessionIndex = sessions.length-1
        }
        this.currentSessionIndex = currentSessionIndex
        
         if (localStorage.getItem('currentCache')) {
            this.currentCache = JSON.parse(localStorage.getItem('currentCache'))
         }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateLocalStorageSessions()
    }

    render() {

        let reportsEl = []

        if (this.state.sessions[this.currentSessionIndex]) {
            const reports = this.state.sessions[this.currentSessionIndex].reports
            for (let i=reports.length-1; i>=0; i--) {    
                let key = i.toString() + reports[i].date
                if (reports[i].id) {
                    key = reports[i].id
                }
                if (reports[i].data) { // Don't show old local storage report format.
                    reportsEl.push(
                        
                            <div className={`${styles.reportRow}`} key={key}>

                                <div className={styles.fieldEditContainer}>
                                    <FieldEdit
                                        value={reports[i].reportName} 
                                        id={i}
                                        onChange={this.onReportChange}
                                    />
                                </div>

                                <div className={styles.buttons}>
                                    <div className={styles.btnOpen}><Button square={false} borders={false} label={intl.get('reports.open')} onClick={(e) => this.updateLocalStorageCache(e, i)} /></div>
                                    <div className={styles.btnErase}><Button square={false} borders={false} icon={<img src={iconErase} alt="icon"/>} onClick={(e) => this.onShowModalDelete(e, i)} /></div>
                                    <div className={styles.btnPdf}><Button square={false} borders={false} icon={<img src={iconPdf} alt="icon" />} onClick={(e) => this.generatePdf(e, i)} /></div>
                                </div>
                                
                            </div>
                    )
                }
            }
        }

        return (

            <>

                <div className={styles.container}>
                    
                    <h2>{intl.get('reports.savedReportsHeader')}</h2>
            
                    {/* <p>{intl.get('reports.savedReportsDescription')}</p> */}

                    <Button square={false} borders={false} icon={<img src={iconSave} alt="icon" />} iconFirst={true} label={intl.get('reports.saveActive')} onClick={() => this.props.onViewChanged(this.props.views.saveReport)} />
                    <Button square={false} borders={false} icon={<img src={iconBack} alt="icon" />} iconFirst={true} label={intl.get('reports.backToActive')} onClick={() => this.handleBackToActive()} />
                    
                    <div className={`${styles.reportsContainer}`}>
                        {reportsEl}
                    </div>

                    <Button
                        label={intl.get('sessions.new')}
                        rounded={true}
                        strongBorders={true}
                        value={0}
                        onClick={() => this.handleReset() }
                    />

                    
                </div>

                <Modal show={this.state.showModalDelete} onHide={() => this.onCloseModalDelete()}>
                    <Modal.Header className={styles.modalHeader}>
                        <Modal.Body>
                            <h3>{intl.get('report.deleteModalTitle')}</h3>
                            <p>{intl.get('report.deleteModalText')}</p>
                        </Modal.Body>
                        <Button 
                            className={styles.btnClose}
                            square
                            icon={<img src={iconClose} alt="icon" />} 
                            onClick={() => this.onCloseModalDelete()}
                        />
                    </Modal.Header>
                    <Modal.Footer>
                        <Button 
                            label={intl.get('general.cancel')} 
                            className={styles.btnCancel} 
                            rounded
                            strongBorders
                            onClick={() => this.onCloseModalDelete()} 
                        />
                        <Button 
                            label={intl.get('general.delete')} 
                            onClick={() => this.onReportDelete()} 
                        />
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showModalReset} onHide={() => this.onCloseModalReset()}>
                    <Modal.Header className={styles.modalHeader}>
                        <Modal.Body>
                        <h3>{intl.get('session.newHeader')}</h3>
                        <p>{intl.get('session.newDescription')}</p>
                        </Modal.Body>
                        <Button 
                            className={styles.btnClose}
                            square
                            icon={<img src={iconClose} alt="icon"/>} 
                            onClick={() => this.onCloseModalReset()}
                        />
                    </Modal.Header>
                    <Modal.Footer>
                    <Button 
                            label={intl.get('general.dontSave')} 
                            onClick={() => this.resetToDefaults()} 
                        />
                        <Button 
                            label={intl.get('general.cancel')} 
                            borders
                            rounded
                            onClick={() => this.onCloseModalReset()} 
                        />
                        <Button 
                            label={intl.get('general.saveAs')} 
                            className={styles.btnCancel} 
                            rounded
                            strongBorders
                            onClick={() => this.onSaveAs()} 
                        />
                    </Modal.Footer>
                </Modal>

            </>

        )

    }

}

export default Reports