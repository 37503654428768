import React, {Component} from 'react'
import intl from 'react-intl-universal'
import products from '../../../data/products'
import helpers from '../../../utils/helpers'
import Slider from '../Slider'
import styles from './RoomTextiles.module.scss'
import ColorPicker from './ColorPicker'
import Switch from '../Switch'
import Radiobutton from '../Radiobutton'
import Button from '../../Lab/Button'
import iconClose from '../../Lab/icon-close.svg'

class RoomTextiles extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            activeLayer: 'layerOne',
        }
       
        
        this.productNames = []
        products.forEach((product) => {
            this.productNames.push(product.name)
        })

        this.onNextWall = this.onNextWall.bind(this)
        this.onPreviousWall = this.onPreviousWall.bind(this)
        this.onWallChanged = this.onWallChanged.bind(this)
        this.onLayerActivated = this.onLayerActivated.bind(this)
        this.onWallReset = this.onWallReset.bind(this)
        
    }

    onNextWall() {
        var activeWall = this.props.absorbantRoom.activeAbsorbantWallIndex
        activeWall++
        if (activeWall === this.props.absorbantRoom.absorbantWalls.length) {
            activeWall = 0
        }
        this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, activeWall)
        this.setFirstLayer()
    }

   onPreviousWall() {
        var activeWall = this.props.absorbantRoom.activeAbsorbantWallIndex
        activeWall--
        if (activeWall < 0) {
            activeWall = this.props.absorbantRoom.absorbantWalls.length - 1
        }
        this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, activeWall)
        this.setFirstLayer()
   }

    onWallChanged(index, wall, layerOneWasChanged, layerTwoWasChanged) {
        this.props.onAbsorbantRoomChanged(this.props.absorbantRoom.id, index, wall, layerOneWasChanged, layerTwoWasChanged)
    }

    onWallReset() {
        this.setFirstLayer()
    }

    setFirstLayer() {
        this.setState({
            activeLayer: 'layerOne',
        })
    }

    onLayerActivated(name) {
        this.setState({
            activeLayer: name
        })
    }

    componentDidMount() {
        this.container.scrollTop = 0
        
    }

    

    render() {

        return (
            <div className={styles.container} ref={ref => (this.container = ref)}>
                <Wall
                    wall = {this.props.absorbantRoom.absorbantWalls[this.props.absorbantRoom.activeAbsorbantWallIndex]}
                    activeLayer = {this.state.activeLayer}
                    onLayerActivated = {this.onLayerActivated}
                    productNames={this.productNames}
                    onChange={this.onWallChanged}
                    index={this.props.absorbantRoom.activeAbsorbantWallIndex}
                    maxWidth={this.props.absorbantRoom.activeAbsorbantWallIndex === 0 || this.props.absorbantRoom.activeAbsorbantWallIndex === 2 ? this.props.roomWidth : this.props.roomDepth}
                    maxHeight={this.props.roomHeight}
                    onReset={this.onWallReset}
                    disableScroll={this.props.disableScroll}
                    enableScroll={this.props.enableScroll}
                />
            </div>
        )
    }
}

export default RoomTextiles

class Wall extends Component {
    
    constructor(props) {
        super(props)
        this.onLayerOneChanged = this.onLayerOneChanged.bind(this)
        this.onLayerTwoChanged = this.onLayerTwoChanged.bind(this)
        this.onLayerActivated = this.onLayerActivated.bind(this)
        this.onReset = this.onReset.bind(this)
        
    }

   

    onLayerOneChanged(layer) {
        let wall = this.props.wall
        wall.layerOne = layer
        this.props.onChange(this.props.index, wall, true, false)
    }

    onLayerTwoChanged(layer) {
        let wall = this.props.wall
        wall.layerTwo = layer
        this.props.onChange(this.props.index, wall, false, true)
    }

    onLayerActivated(name) {
        this.props.onLayerActivated(name)
    }

    onReset() {
        let wall = this.props.wall
        
        wall.layerOne.productIndex = -1
        wall.layerTwo.productIndex = -1
        wall.layerOne.product = null
        wall.layerTwo.product = null

        wall.layerOne.folded = true
        wall.layerTwo.folded = true

        wall.layerOne.distance = 15
        wall.layerTwo.distance = 15
        
        this.props.onChange(this.props.index, wall)
        this.props.onReset()
    }

    render() {

        let layer = null
        if (this.props.activeLayer === 'layerOne') {
            layer = <Layer 
                layer = {this.props.wall.layerOne}
                productNames = {this.props.productNames}
                onChange = {this.onLayerOneChanged}
                maxWidth = {this.props.maxWidth}
                maxHeight = {this.props.maxHeight}
                disableScroll = {this.props.disableScroll}
                enableScroll = {this.props.enableScroll}
            />
        }
        else if (this.props.activeLayer === 'layerTwo') {
            layer = <Layer 
                layer = {this.props.wall.layerTwo}
                productNames = {this.props.productNames}
                onChange = {this.onLayerTwoChanged}
                maxWidth = {this.props.maxWidth}
                maxHeight = {this.props.maxHeight}
                disableScroll = {this.props.disableScroll}
                enableScroll = {this.props.enableScroll}
            />
        }
       
        
        return (
            <div>
                <h3>{intl.get('textiles.wall', {name: this.props.wall.name})}</h3>
                                
                <div className={styles.layerButtonContainer}>

                </div>

                {layer}

            </div>
        )

    }

}



class Layer extends Component {

    constructor(props) {
        super(props)

        this.onProductChanged = this.onProductChanged.bind(this)
        this.onColorChanged = this.onColorChanged.bind(this)
        this.onWidthChanged = this.onWidthChanged.bind(this)
        this.onHeightChanged = this.onHeightChanged.bind(this)
        this.onFoldedChanged = this.onFoldedChanged.bind(this)
        this.onDistanceChanged = this.onDistanceChanged.bind(this)
    }

    disableProductSettings() {
        this.onWidthChanged(0)
        this.onHeightChanged(0)
    }

    activateProductSettings() {
        
    }

    onProductChanged(value) {
        let layer = this.props.layer
        layer.productIndex = value
        layer.colorIndex = -1
        layer.folded = true
        layer.distance = "15"
        layer.product = products[value]
        layer.colorIndex = 0
        if (layer.width === 0) {
            layer.width = this.props.maxWidth
        }
        if (layer.height === 0) {
            layer.height = this.props.maxHeight
        }
        this.props.onChange(layer)
    }

    onColorChanged(index) {
        let layer = this.props.layer
        layer.colorIndex = index
        this.props.onChange(layer)
    }

    onWidthChanged(value) {
        let layer = this.props.layer
        layer.width = value
        this.props.onChange(layer)
    }

    onHeightChanged(value) {
        let layer = this.props.layer
        layer.height = value
        this.props.onChange(layer)
    }

    onFoldedChanged(e) {
        let layer = this.props.layer
        layer.folded = e.target.checked
        this.props.onChange(layer)
    }

    onDistanceChanged(e) {
        let layer = this.props.layer
        layer.distance = e.target.value
        this.props.onChange(layer)
    }

    
    productSettingElements() {
        
        
        return (
            <>
                <ColorPicker
                    onChange={this.onColorChanged}
                    colors={this.props.layer.product.colors.sort()}
                    images={this.props.layer.product.imagesSmall}
                    colorIndex={this.props.layer.colorIndex}
                />
                <div className={styles.controllerContainer}>
                    <Slider 
                        label={intl.get('general.width')}
                        min={0.00}
                        max={this.props.maxWidth}
                        step={0.01}
                        value={this.props.layer.width}
                        onSliderChange={this.onWidthChanged}
                        onSliderTouchStart={this.props.disableScroll}
                        onSliderTouchEnd={this.props.enableScroll}
                        inputMode={"decimal"}
                    />
                </div>

                <div className={styles.controllerContainer}>
                    <Slider 
                        label={intl.get('general.height')}
                        min={0.00}
                        max={this.props.maxHeight}
                        step={0.01}
                        value={this.props.layer.height}
                        onSliderChange={this.onHeightChanged}
                        onSliderTouchStart={this.props.disableScroll}
                        onSliderTouchEnd={this.props.enableScroll}
                        inputMode={"decimal"}
                    />
                </div>

                <div className={styles.flexContainer}>

                    <div className={styles.controllerContainer}>
                    <h4>{intl.get('product.layerFolded')}</h4>
                        <Switch
                            name="folded"
                            checked={ this.props.layer.folded }
                            onChange={this.onFoldedChanged}
                            label={this.props.layer.folded ? intl.get('general.yes') : intl.get('general.no')}
                        />
                    </div>

                    <div className={styles.controllerContainer}>
                        <h4>{intl.get('product.layerDistanceFromWallTitle')}</h4>
                        <Radiobutton
                            name="distance"
                            value="10"
                            checked={this.props.layer.distance === "10" ? true : false}
                            onChange={this.onDistanceChanged}
                            label={intl.get('general.cm', {cm: 10})}
                        />
                        <Radiobutton
                            name="distance"
                            value="15"
                            checked={this.props.layer.distance === "15" ? true : false}
                            onChange={this.onDistanceChanged}
                            label={intl.get('general.cm', {cm: 15})}
                        />
                    </div>

                </div>

                
            </>
        )
    }
   

    render() {

        const productVariant = helpers.productVariantForLayer(this.props.layer)
        
        let productClassTitle = intl.get('product.layerClass')
        if (productVariant && !productVariant.absorptionClass) {
            productClassTitle = intl.get('product.layerUnclassified') 
        }

        let pruductButtons = []

        this.props.productNames.forEach((name, index) => {
            pruductButtons.push(
                <Button 
                    square={false} 
                    borders={false} 
                    label={name} 
                    onClick={this.onProductChanged}
                    value={index}
                    active={index === this.props.layer.productIndex}
                    key={name}
                />
            )
        })

        
        return (
            <div>
                    <div className={styles.productButtons}>
                  
                        {pruductButtons}
                  
                        <Button 
                            square={false} 
                            borders={false} 
                            label={intl.get('general.none')} 
                            icon={this.props.layer.productIndex !== -1 && <img src={iconClose} alt="icon" />}
                            onClick={this.onProductChanged}
                            value={-1}
                            active={this.props.layer.productIndex === -1}
                        />

                    </div>

                    {productVariant ? <h5>{productClassTitle} {productVariant.absorptionClass}</h5> : null}
                {this.props.layer.product != null && this.productSettingElements()}


            </div>
        )

    }

}