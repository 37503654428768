import React from 'react'

import styles from './FieldEdit.module.scss'
import iconEdit from './icon-edit.svg'

class FieldEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editMode: false,
            value: "",
            valueBeforeEdit: "",
        }
        this.enterEditMode = this.enterEditMode.bind(this)
        this.exitEditMode = this.exitEditMode.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    enterEditMode() {
        this.setState({
            editMode: true,
            valueBeforeEdit: this.inputTextEl.value,
        })   
    }

    exitEditMode() {
        this.setState({
            editMode: false
        })
        this.inputTextEl.blur()
        this.props.onChange(this.state.value, this.props.id)
    }

    onSubmit(e) {
        e.preventDefault()
        this.exitEditMode()
    }

    onChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    handleKeyDown(e) {
        if (e.key === 'Escape') {
            this.exitEditMode()
            this.props.onChange(this.state.valueBeforeEdit, this.props.id)
            this.setState({
                value: this.state.valueBeforeEdit
            })
            
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value
        })
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (this.state.editMode) {
            this.inputTextEl.focus()
        }
    }


    render() {

        let labelText = this.props.value
        const maxLength = 25
        if (labelText.length > maxLength) {
            const ending = "..."
            labelText = labelText.substring(0, maxLength-ending.length) + ending
        }
        

        return (
            <div className={`
                ${styles.container} 
                ${this.props.centered && styles.centered}
                ${this.props.large && styles.large}
            `}>
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <input type="text" name="lname" id="lname" 
                        className={`
                            ${styles.inputText}
                            ${this.state.editMode && styles.visible}
                        `}
                        ref={ref => this.inputTextEl = ref} 
                        onChange={(e) => {this.onChange(e)}}
                        onBlur={this.exitEditMode}
                        onKeyDown={this.handleKeyDown}
                        value={this.state.value}
                        maxLength={50}
                    />
                    <button 
                        //disabled="disabled"
                        onClick={this.enterEditMode}
                        type="button" 
                        value={this.state.value}
                        disabled={this.props.disabled}
                        className={`
                            ${styles.button} 
                            ${this.state.editMode && styles.hidden}
                            `}>
                                <span>{labelText}</span>
                                <img src={iconEdit} alt="Edit" />
                    </button>
                </form>

            </div>
        )
    }
}

export default FieldEdit
