import React, {Component} from 'react'

import styles from './Guide.module.scss'

class Guide extends Component {

    constructor(props) {
        super(props)

        this.state = {
            expanded: false
        }

        this.accordion = this.accordion.bind(this)
    }

    accordion(e) {
        if (this.panel.style.maxHeight) {
            this.panel.style.maxHeight = null
            this.accExpand.innerHTML = '+'
            this.setState({
                expanded: false
            })
        }
        else {
            this.panel.style.maxHeight = this.panel.scrollHeight + 20 + 'px'
            this.accExpand.innerHTML = '-'
            this.setState({
                expanded: true
            })
            this.props.onAccordionExpand(this.props.index)
        }
    }

    componentDidUpdate() {
        if (this.state.expanded && this.props.shouldContract) {
            this.accordion()
        }
    }

    render() {

        let contentList = []
        for (var i=0; i<this.props.content.length; i++) {
            contentList.push(<li key={i}>{this.props.content[i]}</li>)
        }

        return (
            <div className={`${styles.container}`}>

                    {/* <div className="container"> */}
                
                        <div className={`${styles.accordion}`} onClick={this.accordion}>
                        
                            <div className={styles.accTitleWrapper}>
                                <h3 className={`${styles.accTitle}`}>{this.props.title}</h3>
                            </div>
                            
                            <div className={styles.accExpandWrapper}>
                                <span className={styles.accExpand} ref={ref => (this.accExpand = ref)}>+</span>
                            </div>

                        </div>

                        <div className={`${styles.panel}`} ref={ref => (this.panel = ref)}>

                            <div className={`${styles.content}`}>

                                <ul>
                                    {contentList}
                                </ul>
                                
                            </div>

                        </div>
               
                </div>
        )
    }

}

export default Guide