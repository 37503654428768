import jsPDF from 'jspdf'
import intl from 'react-intl-universal'
import helpers from '../../../utils/helpers'
import iconLogo from './icon-logo.png'


class LightPDFGenerator {
    constructor() {

        this.filename = intl.get('report.report') + '.pdf'

        this.colors = {
            black: "#000000",
            gray: "#53565a",
            mediumGray: "#898A84",
            lightgray: "#DDDDD9",
            green: "#679B8F",
            lightGreen: "#B3C9C4",
        }

        this.margin = 10
    }

    setParams(absorbantRooms, roomWidth, roomHeight, roomDepth, ceilingMaterial, wallMaterial, floorMaterial) {
        this.absorbantRooms = absorbantRooms
        this.roomWidth = roomWidth
        this.roomHeight = roomHeight
        this.roomDepth = roomDepth
        this.ceilingMaterial = ceilingMaterial
        this.wallMaterial = wallMaterial
        this.floorMaterial = floorMaterial
    }


    start() {

        this.doc = new jsPDF()

        const doc = this.doc

        doc.addImage(iconLogo, 'PNG', 105-21, 277, 42, 4.6);
        this.addByline();

        let nbrOfRooms = 2
        if (this.absorbantRooms.length > 2
            && helpers.atLeastOneProductActiveForRoom(this.absorbantRooms[2])) {
                nbrOfRooms = 3
        }

        this.currentY = 0

        doc.setLineHeightFactor(1.5)
        doc.setFont("helvetica")

        this.currentY += 18
        doc.setTextColor(this.colors.black)
        doc.setFontStyle("bold")
        doc.setFontSize(21)
        doc.text(intl.get('report.report'), this.margin, this.currentY)

        this.currentY += 8
        doc.setTextColor("#53565a")
        doc.setFontStyle("normal")
        doc.setFontSize(10)
        doc.text(doc.splitTextToSize(intl.get('report.description'), 170), this.margin, this.currentY)

        const wallIterator = this.absorbantRooms[0].absorbantWalls

        wallIterator.forEach((wall, wallIndex) => {

            let wallWidth = this.roomWidth
            const wallHeight = this.roomHeight
            if (wall.description === "right"
                || wall.description === "left") {
                    wallWidth = this.roomDepth
            }

            const wallArea = wallWidth * wallHeight

            // Any product for any room for this wall?
            let isCompact = true
            for (let i=0; i<nbrOfRooms; i++) {
                const absorbantRoom = this.absorbantRooms[i]
                absorbantRoom.absorbantWalls[wallIndex].layerOne.product && (isCompact = false)
            }

            let boxHeight = 58
            isCompact && (boxHeight = 28)
            this.maybeNewPage(boxHeight);

            wallIndex === 0 && (this.currentY += 27)

            if(isCompact) {
                return;
            }

            // wallIndex > 0 && (this.currentY += 95)
            doc.setTextColor(this.colors.black)
            doc.setFontStyle("bold")
            doc.setFontSize(11)
            doc.text(intl.get('textiles.wall', {name: wall.name}), this.margin, this.currentY)

            const wallDimensions = helpers.getNumberString(wallWidth, 2) + " x " + helpers.getNumberString(wallHeight, 2) + " m, " + intl.get('general.squareMeter', { area: helpers.getNumberString(wallWidth * wallHeight, 2) })
            doc.setTextColor(this.colors.gray)
            doc.setFontStyle("normal")
            doc.setFontSize(10)
            doc.text(wallDimensions, 210-this.margin, this.currentY, null, null, "right")

            this.currentY += 5

            for (let i=0; i<nbrOfRooms; i++) {
                const absorbantRoom = this.absorbantRooms[i]
                const marginLeft = this.margin + i * (210-2*this.margin) / nbrOfRooms
                const layer = absorbantRoom.absorbantWalls[wallIndex].layerOne
                this.box(helpers.getRoomName(absorbantRoom), wallArea, this.wallMaterial, layer, marginLeft, this.currentY, (210-2*this.margin) / nbrOfRooms, boxHeight, isCompact)
            }

            isCompact ? this.currentY += 41 : this.currentY += 70;

        })

        let boxHeight = 46;

        boxHeight = 30;

        // Total absorption area
        this.currentY += 8;
        this.maybeNewPage(boxHeight);
        doc.setTextColor(this.colors.black)
        doc.setFontStyle("bold")
        doc.setFontSize(11)
        doc.text(intl.get('report.totalAbsorptionArea'), this.margin, this.currentY)

        this.currentY += 5.5
        doc.setTextColor(this.colors.gray)
        doc.setFontStyle("normal")
        doc.setFontSize(10)
        const absorptionDescription = doc.splitTextToSize(intl.get('report.totalAbsorptionAreaDescription'), 170)
        doc.text(absorptionDescription, this.margin, this.currentY)
        if (absorptionDescription.length > 1) {
            this.currentY += absorptionDescription.length * 3
        }

        this.currentY += 5
        const absorptionAreaForEmptyRoom = helpers.absorptionAreaForEmptyRoom(this.ceilingMaterial, this.wallMaterial, this.floorMaterial, this.roomWidth, this.roomHeight, this.roomDepth)
        const surfaceAreaForRoom = helpers.surfaceAreaForRoom(this.roomWidth, this.roomHeight, this.roomDepth)
        for (let i=0; i<nbrOfRooms; i++) {
            const absorbantRoom = this.absorbantRooms[i]
            const marginLeft = this.margin + i * (210-2*this.margin) / nbrOfRooms
            let absorptionArea = absorptionAreaForEmptyRoom
            if (!absorbantRoom.empty) {
                absorptionArea += helpers.absorptionAreaForTextiles(absorbantRoom.absorbantWalls)
            }
            const leftLabel = helpers.getNumberString(absorptionArea, 2) + " m²"
            const rightLabel = helpers.getNumberString(surfaceAreaForRoom, 2) + " m²"
            this.barBox(helpers.getRoomName(absorbantRoom), absorptionArea / surfaceAreaForRoom, marginLeft, this.currentY, (210-2*this.margin) / nbrOfRooms, boxHeight, false, leftLabel, rightLabel)
        }


        // Reverberation time
        this.currentY += 42
        this.maybeNewPage(boxHeight);

        doc.setTextColor(this.colors.black)
        doc.setFontStyle("bold")
        doc.setFontSize(11)
        doc.text(intl.get('reverberation.title'), this.margin, this.currentY)

        this.currentY += 5.5
        doc.setTextColor(this.colors.gray)
        doc.setFontStyle("normal")
        doc.setFontSize(10)
        const reverberationDescription = doc.splitTextToSize(intl.get('report.reverberationDescription'), 170)
        doc.text(reverberationDescription, this.margin, this.currentY)
        if (reverberationDescription.length > 1) {
            this.currentY += reverberationDescription.length * 3
        }

        this.currentY += 5
        const roomVolume = this.roomWidth * this.roomDepth * this.roomHeight
        const reverberationForEmptyRoom = helpers.reverberationFor(absorptionAreaForEmptyRoom, roomVolume)
        for (let i=0; i<nbrOfRooms; i++) {
            const absorbantRoom = this.absorbantRooms[i]
            const marginLeft = this.margin + i * (210-2*this.margin) / nbrOfRooms
            let reverberation = reverberationForEmptyRoom
            if (!absorbantRoom.empty) {
                const absorptionArea = absorptionAreaForEmptyRoom + helpers.absorptionAreaForTextiles(absorbantRoom.absorbantWalls)
                reverberation = helpers.reverberationFor(absorptionArea, roomVolume)
            }
            const leftLabel = helpers.getNumberString(reverberation, 2) + " s"
            let rightLabel = ""
            const diff = reverberationForEmptyRoom - reverberation
            if (diff > 0) {
                rightLabel = "-" + helpers.getNumberString(reverberationForEmptyRoom - reverberation, 2) + " s"
            }
            this.barBox(helpers.getRoomName(absorbantRoom), reverberation / reverberationForEmptyRoom, marginLeft, this.currentY, (210-2*this.margin) / nbrOfRooms, boxHeight, true, leftLabel, rightLabel)
        }

        // Sound pressure
        this.currentY += 45
        this.maybeNewPage(boxHeight);

        doc.setTextColor(this.colors.black)
        doc.setFontStyle("bold")
        doc.setFontSize(11)
        doc.text(intl.get('decibel.relativeLowering'), this.margin, this.currentY)

        this.currentY += 5.5
        doc.setTextColor(this.colors.gray)
        doc.setFontStyle("normal")
        doc.setFontSize(10)
        const audioGainDescription = doc.splitTextToSize(intl.get('report.audioGainDescription'), 170)
        doc.text(audioGainDescription, this.margin, this.currentY)
        if (audioGainDescription.length > 1) {
            this.currentY += audioGainDescription.length * 3
        }

        this.currentY += 5

        for(let i=0; i<nbrOfRooms; i++) {
            const absorbantRoom = this.absorbantRooms[i]
            const marginLeft = this.margin + i * (210-2*this.margin) / nbrOfRooms
            let absorptionArea = absorptionAreaForEmptyRoom
            if (!absorbantRoom.empty) {
                absorptionArea += helpers.absorptionAreaForTextiles(absorbantRoom.absorbantWalls)
            }
            const dBReduction = helpers.noiseLevelReduction(absorptionAreaForEmptyRoom, absorptionArea)
            const audioGain = helpers.dBReductionToGain(dBReduction)
            const leftLabel = ""
            const rightLabel = "-" + helpers.getNumberString(dBReduction, 2) + " dB"
            this.barBox(helpers.getRoomName(absorbantRoom), audioGain, marginLeft, this.currentY, (210-2*this.margin) / nbrOfRooms, boxHeight, true, leftLabel, rightLabel)

        }

        doc.save(this.filename)
    }

    addByline() {
        let byline = "";
        const storedUser = JSON.parse(sessionStorage.getItem("currentUser"));

        if (storedUser.firstName) {
            byline += storedUser.firstName + ' ' + storedUser.lastName;
        }
        if (storedUser.phoneNumber) {
            byline += " | " + storedUser.phoneNumber;
        }
        if (storedUser.email) {
            byline += " | " + storedUser.email;
        }
        if(byline!=="") {
            this.doc.setTextColor(this.colors.gray)
            this.doc.setFontSize(10)
            this.doc.text(byline, 105, 288,{align:"center"})
        }
    }

    maybeNewPage(boxHeight) {
        if (this.currentY > 297 - boxHeight - 30) {
            this.doc.addPage()
            this.currentY = 20
            this.doc.addImage(iconLogo, 'PNG', 105-21, 277, 42, 4.6);
            this.addByline();
        }
    }

    barBox(header, quotient, xpos, ypos, width, height, numeratorFocus=false, leftLabel, rightLabel) {

        const doc = this.doc
        const padding = 4

        doc.setDrawColor(this.colors.lightgray)

        doc.rect(xpos, ypos, width, height)

        ypos += padding + 2

        doc.setTextColor(this.colors.black)
        doc.setFontSize(8)
        doc.setFontStyle("bold")
        doc.text(header, xpos + padding, ypos)

        ypos += 4
        doc.line(xpos, ypos, xpos+width, ypos)

        ypos += 9
        doc.setTextColor(this.colors.green)
        numeratorFocus && doc.setTextColor(this.colors.gray)
        doc.setFontSize(16)
        doc.setFontStyle("normal")
        doc.text(leftLabel, xpos + padding, ypos)

        doc.setTextColor(this.colors.gray)
        doc.setFontSize(10)
        if (numeratorFocus) {
            doc.setTextColor(this.colors.green)
            doc.setFontSize(16)
        }
        doc.text(rightLabel, xpos + width - padding, ypos, "right")

        ypos += 4
        doc.setFillColor(this.colors.lightGreen)
        numeratorFocus && doc.setFillColor(this.colors.mediumGray)
        const leftBarWidth = quotient * (width - 2 * padding)
        doc.rect(xpos + padding, ypos, leftBarWidth, 2.5, 'F')
        doc.setFillColor(this.colors.mediumGray)
        numeratorFocus && doc.setFillColor(this.colors.lightGreen)
        const rightBarWidth = width - 2 * padding - leftBarWidth
        doc.rect(xpos + padding + leftBarWidth, ypos, rightBarWidth, 2.5, 'F')
    }



    box(header, area, material, layer, xpos, ypos, width, height, isCompact) {

        const doc = this.doc
        const padding = 4

        doc.setDrawColor(this.colors.lightgray)
        doc.rect(xpos, ypos, width, height)

        ypos += padding + 2

        doc.setTextColor(this.colors.black)
        doc.setFontSize(8)
        doc.setFontStyle("bold")
        doc.text(header, xpos+padding, ypos)

        ypos += 4
        doc.line(xpos, ypos, xpos+width, ypos)

        ypos += 6
        let name = intl.get(material.name + '.name')

        if (layer && layer.product) {
            name = layer.product.name

            if (layer.colorIndex >= 0 && layer.product.colors.length > 0) {
                name += ', ' + layer.product.colors[layer.colorIndex]
            }


        }
        doc.setTextColor(this.colors.black)
        doc.setFontSize(10)
        doc.setFontStyle("normal")
        doc.text(name, xpos+padding, ypos)

        ypos += 6
        if (!layer || !layer.product) {
            let alpha = this.wallMaterial.absorptionCoefficient
            alpha = helpers.getNumberString(parseFloat(alpha), 2)
            alpha = "aw = " + alpha
            if (this.wallMaterial.shapeIndicator) {
                alpha += " (" + this.wallMaterial.shapeIndicator + ")"
            }
            doc.setTextColor(this.colors.gray)
            doc.setFontSize(10)
            doc.setFontStyle("normal")
            doc.text(alpha, xpos+padding, ypos)

            ypos+= 4
            !isCompact && (ypos += 28)

        }
        else if (layer && layer.product) {
            const productVariant = helpers.productVariantForLayer(layer)
            let productClass = intl.get('report.class') + ' ' + productVariant.absorptionClass
            doc.setTextColor(this.colors.gray)
            doc.setFontSize(10)
            doc.setFontStyle("normal")
            doc.text(productClass, xpos+padding, ypos)

            ypos += 6
            let alpha = productVariant.absorptionCoefficient
            let alphaStr = 'aw = '
            alphaStr += helpers.getNumberString(parseFloat(alpha), 2)
            if (productVariant.shapeIndicator) {
                alphaStr += ' (' + productVariant.shapeIndicator + ')'
            }
            doc.text(alphaStr, xpos+padding, ypos)

            ypos+= 4
            doc.line(xpos + padding, ypos, xpos + width - padding, ypos)

            const image = helpers.getTextileImage(layer)
            if (image) {
                const imageData = new Image()
                imageData.src = image
                doc.addImage(imageData, 'JPEG', xpos+width-padding-15, ypos-16-2.7, 15, 15, imageData.src, 'NONE')
            }

            ypos += 6
            const layerWidthString = intl.get('general.width') + ': ' + helpers.getNumberString(layer.width, 2) + ' m'
            doc.text(layerWidthString, xpos + padding, ypos)

            ypos += 6
            const layerHeightString = intl.get('general.height') + ': ' + helpers.getNumberString(layer.height, 2) + ' m'
            doc.text(layerHeightString, xpos + padding, ypos)

            ypos += 6
            let implementation = ''
            layer.folded ?
                implementation = intl.get('report.implementationFolded')
                :
                implementation = intl.get('report.implementationFlat')
            implementation += ", "
            implementation += intl.get('report.implementationDistance', {distance: layer.distance})
            doc.text(implementation, xpos + padding, ypos)

            ypos+= 4

        }
    }
}

export default LightPDFGenerator
