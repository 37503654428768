import React, { Component } from 'react'
import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import intl from 'react-intl-universal'

import helpers from '../../utils/helpers'
import styles from './Room4.module.scss'
import imgPlaceholder from './img/black.png'

import Button from '../Lab/Button'
import iconPlus from '../Lab/icon-plus.svg'
import iconFullscreen from '../Lab/icon-fullscreen.svg'

import Audio from './Audio'

class Room4 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      didMount: false,
    }

    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)
    this.onWindowResize = this.onWindowResize.bind(this)
    this.shouldAnimateCamera = false
    
    const room = this.props.absorbantRoom
    this.productIndexFront = room.absorbantWalls[0].layerOne.productIndex
    this.productIndexRight = room.absorbantWalls[1].layerOne.productIndex
    this.productIndexBack = room.absorbantWalls[2].layerOne.productIndex
    this.productIndexLeft = room.absorbantWalls[3].layerOne.productIndex

    this.colorIndexFront = room.absorbantWalls[0].layerOne.colorIndex
    this.colorIndexRight = room.absorbantWalls[1].layerOne.colorIndex
    this.colorIndexBack = room.absorbantWalls[2].layerOne.colorIndex
    this.colorIndexLeft = room.absorbantWalls[3].layerOne.colorIndex
  }

  onWindowResize() {

    const dist = this.camera.position.distanceTo(this.roomHolder.position) - 0.5 *  this.roomHolder.scale.z

    // FOV to match viewport height. 
    const fov1 = 2 * Math.atan(this.roomHolder.scale.y / (2 * dist)) * (180 / Math.PI)

    // FOV to match viewport width.
    const viewportAspect = this.mount.clientWidth / this.mount.clientHeight
    const fov2 = 2 * Math.atan( (this.roomHolder.scale.x / viewportAspect) / (2 * dist)) * (180 / Math.PI)

    const fov = Math.max(fov1, fov2)
    
    // let fovOffset = 12
    let fovOffset = 10
    if (this.props.fullWidthActive) {
      fovOffset = 6
    }
    this.camera.fov = fov + fovOffset
    
    this.frameId = window.requestAnimationFrame(() => {
      this.updateRenderer()
      this.animate()
      if (this.shouldResize()) {
        this.onWindowResize()
      }

    })
    
  }

  animateCamera() {
    if (this.shouldAnimateCamera) {
      window.requestAnimationFrame(() => {
        TWEEN.update()
        this.animate()
        this.animateCamera()
      })
    }
  }

  shouldResize() {
    return this.props.fullWidthStartTime > (Date.now() - this.props.fullWidthMilliseconds)
  }


  start() {
    // this.positionOverlays()
    this.onWindowResize()
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {
    this.positionOverlays()
    this.renderer.render(this.scene, this.camera)
    
  }

  updateRenderer() {
    this.renderer.setSize(this.mount.clientWidth, this.mount.clientHeight, false)
    this.camera.aspect = this.mount.clientWidth / this.mount.clientHeight
    this.camera.updateProjectionMatrix()
    // if (this.resizeRendererToPixelRatio(this.renderer)) {
    //     const canvas = this.renderer.domElement
    //     this.camera.aspect = canvas.clientWidth / canvas.clientHeight
    //     this.camera.updateProjectionMatrix()
    // }
    
  }

  resizeRendererToPixelRatio(renderer) {
      const canvas = renderer.domElement
      const pixelRatio = window.devicePixelRatio
      const width = canvas.clientWidth * pixelRatio | 0
      const height = canvas.clientHeight * pixelRatio | 0
      const needResize = canvas.width !== width || canvas.height !== height
      if (needResize) {
          renderer.setSize(width, height, false)
      }
      return needResize
  }

  componentDidMount() {
    
    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.shadowMap.enabled = true
    this.renderer = renderer
    this.mount.appendChild(renderer.domElement)

    const camera = new THREE.PerspectiveCamera(
        75,
        this.mount.clientWidth / this.mount.clientHeight,
        0.1,
        6
    )

    this.camera = camera
    camera.position.setY(3)
    camera.lookAt(0, 0, 0)

    const scene = new THREE.Scene()
    this.scene = scene
    scene.background = new THREE.Color(0xfaf8f7)
    // scene.add(new THREE.AxesHelper(10))

    const controls = new OrbitControls(camera, this.mount)
    controls.enableKeys = false
    // controls.enableDamping = true
    // controls.minPolarAngle = 0.8;
		controls.maxPolarAngle = 1.6492365800339353
    // controls.dampingFactor = 0.07
    // controls.rotateSpeed = 0.07
    controls.enableZoom = false
    controls.enabled = false
    controls.addEventListener('change', (() => {
      this.positionOverlays()
      this.frameId = window.requestAnimationFrame(this.animate)
    }))
    this.controls = controls

    const roomHolder = new THREE.Object3D()
    this.roomHolder = roomHolder
    scene.add(roomHolder)

    const wallGeoX = new THREE.BoxBufferGeometry(1, 1, 0.02)
    const wallGeoZ = new THREE.BoxBufferGeometry(0.98, 1, 0.02)
    const wallMat = new THREE.MeshLambertMaterial({color: 0xffffff})
    this.wallMeshes = {}
    this.wallMeshes.front = new THREE.Mesh(wallGeoX, wallMat) 
    this.wallMeshes.front.position.z = 0.5
    this.wallMeshes.front.castShadow = true
    this.wallMeshes.back = new THREE.Mesh(wallGeoX, wallMat)
    this.wallMeshes.back.position.z = -0.5
    this.wallMeshes.back.castShadow = true
    this.wallMeshes.left = new THREE.Mesh(wallGeoZ, wallMat)
    this.wallMeshes.left.position.x = -0.49
    this.wallMeshes.left.rotation.y = Math.PI / 2
    this.wallMeshes.left.castShadow = true
    this.wallMeshes.right = new THREE.Mesh(wallGeoZ, wallMat)
    this.wallMeshes.right.position.x = 0.49
    this.wallMeshes.right.rotation.y = Math.PI / 2
    this.wallMeshes.right.castShadow = true

    roomHolder.add(this.wallMeshes.front)
    roomHolder.add(this.wallMeshes.back)
    roomHolder.add(this.wallMeshes.left)
    roomHolder.add(this.wallMeshes.right)

    this.textileMeshes = {}
    this.textileOffset = 0.011
    const textileGeo = new THREE.PlaneBufferGeometry(0.96, 1)
    const textileTexture = new THREE.TextureLoader().load(imgPlaceholder)
        
    const textileMatFront = new THREE.MeshBasicMaterial({map: textileTexture})
    this.textileMeshes.front = new THREE.Mesh(textileGeo, textileMatFront)
    this.textileMeshes.front.rotation.y = Math.PI
    this.textileMeshes.front.position.z = 0.5 - this.textileOffset
    this.loadTexture(this.props.absorbantRoom.absorbantWalls[0].layerOne, this.textileMeshes.front)

    const textileMatRight = new THREE.MeshBasicMaterial({map: textileTexture})
    this.textileMeshes.right = new THREE.Mesh(textileGeo, textileMatRight)
    this.textileMeshes.right.rotation.y = -Math.PI / 2
    this.textileMeshes.right.position.x = 0.5 - this.textileOffset
    this.loadTexture(this.props.absorbantRoom.absorbantWalls[1].layerOne, this.textileMeshes.right)

    const textileMatBack = new THREE.MeshBasicMaterial({map: textileTexture})
    this.textileMeshes.back = new THREE.Mesh(textileGeo, textileMatBack)
    this.textileMeshes.back.position.z = -0.5 + this.textileOffset
    this.loadTexture(this.props.absorbantRoom.absorbantWalls[2].layerOne, this.textileMeshes.back)

    const textileMatLeft = new THREE.MeshBasicMaterial({map: textileTexture})
    this.textileMeshes.left = new THREE.Mesh(textileGeo, textileMatLeft)
    this.textileMeshes.left.rotation.y = Math.PI / 2
    this.textileMeshes.left.position.x = -0.5 + this.textileOffset
    this.loadTexture(this.props.absorbantRoom.absorbantWalls[3].layerOne, this.textileMeshes.left)

    roomHolder.add(this.textileMeshes.front)
    roomHolder.add(this.textileMeshes.right)
    roomHolder.add(this.textileMeshes.back)
    roomHolder.add(this.textileMeshes.left)

    const groundGeo = new THREE.PlaneBufferGeometry(1.5, 1.5)
    const groundMat = new THREE.ShadowMaterial()
    groundMat.opacity = 0.1
    const groundMesh = new THREE.Mesh(groundGeo, groundMat)
    groundMesh.position.y = -0.25
    groundMesh.rotation.x = -Math.PI / 2
    groundMesh.receiveShadow = true
    this.groundMesh = groundMesh
    roomHolder.add(groundMesh)

    const floorGeo = new THREE.PlaneBufferGeometry(1, 1)
    const floorMat = new THREE.MeshBasicMaterial({color: 0xffffff})
    const floorMesh = new THREE.Mesh(floorGeo, floorMat)
    floorMesh.position.y = -0.25
    floorMesh.rotation.x = -Math.PI / 2
    this.floorMesh = floorMesh
    roomHolder.add(floorMesh)

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
    scene.add(ambientLight)

    // const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8)
    const directionalLight = new THREE.SpotLight(0xffffff, 0.8)
    directionalLight.angle = Math.PI / 10
    // directionalLight.position.set(0.7, 4, -2);
    directionalLight.position.set(0.7, 3, -2);
    directionalLight.target.position.set(0, 0, 0);
    directionalLight.castShadow = true
    directionalLight.shadow.camera.left = -1
    directionalLight.shadow.camera.right = 1
    directionalLight.shadow.camera.top = -1
    directionalLight.shadow.camera.bottom = 1
    directionalLight.shadow.mapSize.width = 1024
    directionalLight.shadow.mapSize.height = 1024
    // scene.add( new THREE.CameraHelper( directionalLight.shadow.camera ) );
    scene.add(directionalLight)

    this.setState({
      didMount: true
    })

    this.start()

    window.addEventListener("resize", this.onWindowResize)
    
  }

  getTextileImage(layer) {
    if (!layer.product || !layer.product.images) {
      return imgPlaceholder
    }
    else if (layer.product.images) {
      if (layer.colorIndex < 0) {
        return layer.product.images[layer.product.images.length - 1]
      }
      else  {
        return layer.product.images[layer.colorIndex]
      }
    }
  }

  loadTexture(layer, mesh) {  
      const img = this.getTextileImage(layer)
      const texture = new THREE.TextureLoader().load(img, ((texture) => {
        this.frameId = window.requestAnimationFrame(this.animate)
      }))
      mesh.material.map = texture
  }

  positionOverlays() {
    
    this.positionOverlay(this.wallMeshes.front, this.overlayFront, "yfront")
    this.positionOverlay(this.wallMeshes.back, this.overlayBack, "yback")
    this.positionOverlay(this.wallMeshes.right, this.overlayRight, "xright")
    this.positionOverlay(this.wallMeshes.left, this.overlayLeft, "xleft")
    this.positionOverlay(this.floorMesh, this.overlayCenter)
    this.positionOverlay(this.floorMesh, this.overlayAudio)
    this.positionOverlayBottomRight(this.overlayCorner)
    
  }

  positionOverlay(toObject, el, limit) {
    if (el) {
      const tempVector = new THREE.Vector3()
      toObject.getWorldPosition(tempVector)
      tempVector.project(this.camera)
      let xpos = (tempVector.x * 0.5 + 0.5) * this.mount.clientWidth
      let ypos = (tempVector.y * -0.5 + 0.5) * this.mount.clientHeight
      
      if (limit === "xright") {
        if (xpos < this.mount.clientWidth/2 + 140) {
          xpos = this.mount.clientWidth/2 + 140
        }
      }
      if (limit === "xleft") {
        if (xpos > this.mount.clientWidth/2 - 140) {
          xpos = this.mount.clientWidth/2 - 140
        }
      }
      if (limit === "yback") {
        if (ypos > this.mount.clientHeight/2 - 105) {
          ypos = this.mount.clientHeight/2 - 105
        }
      }
      if (limit === "yfront") {
        if (ypos < this.mount.clientHeight/2 + 90) {
          ypos = this.mount.clientHeight/2 + 90
        }
      }
      el.style.transform = `translate(-50%, -50%) translate(${xpos}px, ${ypos}px)`
      return true
    }
    return false
  }

  positionOverlayBottomRight(el) {
    if (el) {
      const tempVector = new THREE.Vector3()
      const toObjectX = this.wallMeshes.right
      const toObjectY = this.wallMeshes.front

      toObjectX.getWorldPosition(tempVector)
      tempVector.project(this.camera)
      let xpos = (tempVector.x * 0.5 + 0.5) * this.mount.clientWidth
      xpos *= 1.11

      if (xpos < this.mount.clientWidth/2 + 140) {
        xpos = this.mount.clientWidth/2 + 140
      }

      toObjectY.getWorldPosition(tempVector)
      tempVector.project(this.camera)
      let ypos = (tempVector.y * -0.5 + 0.5) * this.mount.clientHeight
      ypos *= 1.11

      if (ypos < this.mount.clientHeight/2 + 90) {
        ypos = this.mount.clientHeight/2 + 90
      }

      el.style.transform = `translate(-50%, -50%) translate(${xpos}px, ${ypos}px)`

      return true
    }
    return false
  }
 
  getLabel(layer) {
    let label = "+"
    const productVariant = helpers.productVariantForLayer(layer)
    if (productVariant
      && layer.width > 0
      && layer.height > 0) {
      label = productVariant.absorptionClass
      if (productVariant.shapeIndicator) {
        label += " (" + productVariant.shapeIndicator + ")"
      }
    }
    return label
  }

  getNameLabel(layer) {
    let label = ""
    let labelEl = null
    if  (layer.product) {
      label = layer.product.name
      if (layer.colorIndex >= 0) {
        label += ", " + layer.product.colors[layer.colorIndex]
      }
      labelEl = <span className={styles.nameLabel}>{label}</span>
      
    }
    return labelEl
  }

  componentDidUpdate(prevProps, prevState) {
  
      let longestSide = this.props.roomWidth
      if (this.props.roomDepth > longestSide) { longestSide = this.props.roomDepth }
      if (this.props.roomHeight > longestSide) { longestSide = this.props.roomHeight }
      
      const width = this.props.roomWidth / longestSide
      const height = this.props.roomHeight / longestSide
      const depth = this.props.roomDepth / longestSide

      this.wallMeshes.front.scale.x = width
      this.wallMeshes.front.scale.y = height
      this.wallMeshes.front.position.z = depth / 2

      this.wallMeshes.back.scale.x = width
      this.wallMeshes.back.scale.y = height
      this.wallMeshes.back.position.z = -depth / 2

      this.wallMeshes.left.scale.x = depth
      this.wallMeshes.left.scale.y = height
      this.wallMeshes.left.position.x = -width / 2 + 0.01

      this.wallMeshes.right.scale.x = depth
      this.wallMeshes.right.scale.y = height
      this.wallMeshes.right.position.x = width / 2 - 0.01

      this.groundMesh.position.y = -height / 2 + 0.001
      
      this.floorMesh.position.y = -height / 2
      this.floorMesh.scale.x = width
      this.floorMesh.scale.y = depth

      const absorbantRoom = this.props.absorbantRoom
      const layerFront = absorbantRoom.absorbantWalls[0].layerOne

      if (layerFront.width >= 0) {
        this.textileMeshes.front.scale.x = width * (layerFront.width / this.props.roomWidth)
      }
      if (layerFront.height >= 0) {
        this.textileMeshes.front.scale.y = height * (layerFront.height / this.props.roomHeight)
      }
      this.textileMeshes.front.position.z = this.wallMeshes.front.position.z - this.textileOffset
      if (layerFront.productIndex !== this.productIndexFront 
        || layerFront.colorIndex !== this.colorIndexFront) {
          this.loadTexture(layerFront, this.textileMeshes.front)
          this.productIndexFront = layerFront.productIndex
          this.colorIndexFront = layerFront.colorIndex
      }

      const layerRight = absorbantRoom.absorbantWalls[1].layerOne
      if (layerRight.width >= 0) {
        this.textileMeshes.right.scale.x = (depth + 0.02) * (layerRight.width / this.props.roomDepth)
      }
      if (layerRight.height >= 0) {
        this.textileMeshes.right.scale.y = height * (layerRight.height / this.props.roomHeight)
      }
      this.textileMeshes.right.position.x = this.wallMeshes.right.position.x - this.textileOffset
      if (layerRight.productIndex !== this.productIndexRight
        || layerRight.colorIndex !== this.colorIndexRight) {
        this.loadTexture(layerRight, this.textileMeshes.right)
        this.productIndexRight = layerRight.productIndex
        this.colorIndexRight = layerRight.colorIndex
      }

      const layerBack = absorbantRoom.absorbantWalls[2].layerOne
      if (layerBack.width >= 0) {
        this.textileMeshes.back.scale.x = width * (layerBack.width / this.props.roomWidth)
      }
      if (layerBack.height >= 0) {
        this.textileMeshes.back.scale.y = height * (layerBack.height / this.props.roomHeight)
      }
      this.textileMeshes.back.position.z = this.wallMeshes.back.position.z + this.textileOffset
      if (layerBack.productIndex !== this.productIndexBack
        || layerBack.colorIndex !== this.colorIndexBack) {
        this.loadTexture(layerBack, this.textileMeshes.back)
        this.productIndexBack = layerBack.productIndex
        this.colorIndexBack = layerBack.colorIndex
      }

      const layerLeft = absorbantRoom.absorbantWalls[3].layerOne
      if (layerLeft.width >= 0) {
        this.textileMeshes.left.scale.x = (depth + 0.02) * (layerLeft.width / this.props.roomDepth)
      }
      if (layerLeft.height >= 0) {
        this.textileMeshes.left.scale.y = height * (layerLeft.height / this.props.roomHeight)
      }
      this.textileMeshes.left.position.x = this.wallMeshes.left.position.x + this.textileOffset
      if (layerLeft.productIndex !== this.productIndexLeft
        || layerLeft.colorIndex !== this.colorIndexLeft) {
        this.loadTexture(layerLeft, this.textileMeshes.left)
        this.productIndexLeft = layerLeft.productIndex
        this.colorIndexLeft = layerLeft.colorIndex
      }

      if (this.props.fullWidthActive 
        && !prevProps.fullWidthActive
        && !this.shouldAnimateCamera) {
          this.controls.enabled = false
          this.moveCameraTo({x: 0, y: 2, z: 1}, 250, TWEEN.Easing.Quadratic.In).onComplete(() => {
            this.moveCameraTo({x: 0.9, y: 2, z: 2}, 250, TWEEN.Easing.Quadratic.Out).onComplete(() => {
              this.shouldAnimateCamera = false
              this.controls.enabled = true
            })
          })
          this.shouldAnimateCamera = true
          this.animateCamera()
      }
      if (!this.props.fullWidthActive 
        && prevProps.fullWidthActive
        && !this.shouldAnimateCamera) {
          this.controls.enabled = false
          this.moveCameraTo({x: 0, y: 2, z: 1}, 250, TWEEN.Easing.Quadratic.In).onComplete(() => {
            this.moveCameraTo({x: 0, y: 3, z: 0}, 250, TWEEN.Easing.Quadratic.Out).onComplete(() => {
              this.shouldAnimateCamera = false
              
            })
          })
          this.shouldAnimateCamera = true
          this.animateCamera()
      }

      

      if (this.shouldResize()) {
        this.onWindowResize()
      }
      else {
        this.frameId = window.requestAnimationFrame(() => {
          this.positionOverlays()
          this.animate()
        })
      }
    
  }

  moveCameraTo(newPos, duration, easing) {
    const tween = new TWEEN.Tween(this.camera.position).to(newPos, duration).easing(easing)
      .onUpdate((pos) => {
        this.camera.position.setY(pos.y)
        this.camera.position.setZ(pos.z)
        this.camera.position.setX(pos.x)
        this.camera.lookAt(0, 0, 0)
        this.controls.update()
      })
      .start()
    return tween
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
    window.removeEventListener("resize", this.onWindowResize)
  }

  render() {

 
    
    const room = this.props.absorbantRoom

    if(this.state.didMount) {
      if(!room.empty && room.id > 1 && !this.props.atLeastOneProductActive(room.id) &&!this.props.inputpaneActive) {
        this.scene.visible = false
      }
      else {
        this.scene.visible = true
      }
    }
    
    const labelFront = this.getLabel(room.absorbantWalls[0].layerOne)
    const nameLabelFront = this.getNameLabel(room.absorbantWalls[0].layerOne)

    const labelRight = this.getLabel(room.absorbantWalls[1].layerOne)
    const nameLabelRight = this.getNameLabel(room.absorbantWalls[1].layerOne)

    const labelBack = this.getLabel(room.absorbantWalls[2].layerOne)
    const nameLabelBack = this.getNameLabel(room.absorbantWalls[2].layerOne)
    
    const labelLeft = this.getLabel(room.absorbantWalls[3].layerOne)
    const nameLabelLeft = this.getNameLabel(room.absorbantWalls[3].layerOne)

    let btnVolumeAndSurfaces = 
      <div className={`${styles.overlayFront}`} ref={(overlayFront) => {this.overlayFront = overlayFront}}>
        <Button rounded={true} borders={true} label={intl.get('volume.volumeAndSurfaces')} onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 0) }} />
      </div>

    let btnsWalls = 
      <>
        <div className={`${styles.overlayFront}`} ref={(overlayFront) => {this.overlayFront = overlayFront}}>
          <div className={styles.wallBtn}>
            <Button 
              circular={true} 
              label={labelFront} 
              onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 0) }} 
              doubleBorders={this.props.inputpaneActive && this.props.absorbantRoom.activeAbsorbantWallIndex === 0}
            />
          </div>
          {nameLabelFront}
        </div>
        <div className={`${styles.overlayRight}`} ref={(overlayRight) => {this.overlayRight = overlayRight}}>
          <div className={styles.wallBtn}>
            <Button 
              circular={true} 
              label={labelRight} 
              onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 1) }} 
              doubleBorders={this.props.inputpaneActive && this.props.absorbantRoom.activeAbsorbantWallIndex === 1}  
            />
          </div>
          {nameLabelRight}
        </div>
        <div className={`${styles.overlayBack}`} ref={(overlayBack) => {this.overlayBack = overlayBack}}>
          <div className={styles.wallBtn}>
            <Button 
              circular={true} 
              label={labelBack} 
              onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 2) }} 
              doubleBorders={this.props.inputpaneActive && this.props.absorbantRoom.activeAbsorbantWallIndex === 2}
            />
          </div>
          {nameLabelBack}
        </div>
        <div className={`${styles.overlayLeft}`} ref={(overlayLeft) => {this.overlayLeft = overlayLeft}}>
          <div className={styles.wallBtn}>
            <Button 
              circular={true} 
              label={labelLeft}  
              onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 3) }} 
              doubleBorders={this.props.inputpaneActive && this.props.absorbantRoom.activeAbsorbantWallIndex === 3}  
            />
          </div>
          {nameLabelLeft}
        </div>
      </>
    
    let btnAddTextiles = 
      <div className={`${styles.overlayCenter}`} ref={(overlayCenter) => {this.overlayCenter = overlayCenter}}>
        <Button rounded={true} dotted={true} icon={<img src={iconPlus} alt="icon"/>} iconFirst={true} label={intl.get('general.addTextiles')} onClick={() => {this.props.onActivateAbsorbantWall(this.props.absorbantRoom.id, 0) }} />
      </div>

    let btnFullWidth = 
      <div className={`${styles.overlayCorner}`} ref={(overlayCorner) => {this.overlayCorner = overlayCorner}}>
        <Button icon={<img src={iconFullscreen} alt="icon"/>} circular={true} onClick={() => {this.props.openFullWidth(this.props.absorbantRoom.id) }} />
      </div>

    let audio = 
      <div className={`${styles.overlayAudio}`} ref={(overlayAudio) => {this.overlayAudio = overlayAudio}}>
        <Audio 
          dBReduction = {this.props.dBReduction} 
          startedPlaying = {this.props.startedPlaying}
          index = {this.props.absorbantRoom.id}
          shouldPause = {this.props.shouldPause}
        />
      </div>

      
    
    
    return (
      <>

        <div className={`${styles.container} ${this.state.didMount && styles.mounted} ${this.props.fullWidthActive && styles.fullWidth}`} ref={(mount) => { this.mount = mount }}></div>

        {room.empty &&
          <div className={`${styles.overlay} ${(this.props.inputpaneActive || this.props.fullWidthActive) && styles.hidden}`}>
            {btnVolumeAndSurfaces}
          </div>
        }

        

        {!room.empty 
          && (room.id === 1 || this.props.atLeastOneProductActive(room.id) || (this.props.inputpaneActive && room.id === this.props.activeAbsorbantRoomIndex)) 
          &&
        <div className={`${styles.overlay} ${this.props.fullWidthActive && styles.hidden}`}>
          {btnsWalls}
        </div>
        }

        {!room.empty && room.id > 1 && !this.props.atLeastOneProductActive(room.id) &&!this.props.inputpaneActive &&
          <div className={`${styles.overlay}`}>
            {btnAddTextiles}
          </div>
        }

        {this.state.didMount && this.scene.visible &&
          <div className={`${styles.overlay} ${this.props.fullWidthActive && styles.hidden}`}>
            {btnFullWidth}
            {audio}
            
          </div>
        }

      </>

    )

  }

}

  
export default Room4