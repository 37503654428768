import React, {Component} from 'react'
import intl from 'react-intl-universal'
import styles from './Decibel.module.scss'
import HorizontalBar from './HorizontalBar'

class Decibel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: this.props.show
        }        
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            setTimeout(() => {
                this.setState({
                    show: this.props.show
                })
            }, 200)
        }
    }

    render() {

        const {currentLocale} = intl.getInitOptions()

        const dBReduction = Number.parseFloat(this.props.dBReduction).toFixed(3)
        
        

        let formulaSource = null
        if (this.props.showFormulaSource) { 
            // formulaSource = <><em>enligt formeln ASPL = 10 log (SA/SB), där SA = absorptionsarea med valda textilier och SB = absorptionsarea utan valda textilier. Se s. 10 i <a href="https://www2.owenscorning.com/quietzonepro/pdfs/NoiseControlDesignGuide.pdf">denna pdf</a></em></>
            formulaSource = <>{intl.get('decibel.formulaSource')}</>
        }

        return(

            <>
            {this.props.show &&
                <div className={styles.container}>
                
                    <h3>{intl.get('decibel.relativeLowering')}</h3>

                    <div className={styles.chartContainer}>

                        <HorizontalBar
                            percent={(Math.pow(10, -dBReduction / 20)) * 100}
                            labelRight={intl.get('decibel.relativeDB', {
                                dB: this.props.dBReduction.toLocaleString(currentLocale, {
                                    minimumFractionDigits: 2, 
                                    maximumFractionDigits: 2
                                })
                            })}
                            description={intl.get('general.withTextiles')}
                        />
                
                    {this.props.showFormulaSource &&
                        <p className={styles.formulaDescription}>{formulaSource}</p>
                    }
                    
                    </div> 
                
                </div>

            }
            </>

        )

    }

}

export default Decibel