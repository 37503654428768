import React from 'react';
import './loadingBackdrop.scss';

function Backdrop() {
  return (
    <div id="backdrop">
      <div id="backdrop__icon">
        <div className="loadingio-spinner-rolling-qoc7v6b3gdf">
          <div className="ldio-6cciijfifcw">
            <div></div>
          </div>
        </div>
        <span id="backdrop__text">Loading<span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></span>
      </div>
    </div>
  );
}

export default Backdrop;
