import React from 'react'

import styles from './NumberEdit.module.scss'
import iconEdit from './icon-edit.svg'

class NumberEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editMode: false,
            valueBeforeEdit: "",
        }
        this.enterEditMode = this.enterEditMode.bind(this)
        this.exitEditMode = this.exitEditMode.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    enterEditMode() {
        this.setState({
            editMode: true,
            valueBeforeEdit: this.inputTextEl.value,
        })   
    }

    exitEditMode() {
        this.setState({
            editMode: false
        })
        this.inputTextEl.blur()
    }

    onSubmit(e) {
        e.preventDefault()
        this.exitEditMode()
    }

    onChange(e) {
        this.props.onChange(e.target.value, this.props.id)
    }

    handleKeyDown(e) {
        if (e.key === 'Escape') {
           
            this.props.onChange(this.state.valueBeforeEdit, this.props.id)
            this.exitEditMode()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (this.state.editMode) {
            this.inputTextEl.focus()
        }
    }


    render() {

        let labelText = this.props.value
        const maxLength = 25
        if (labelText.length > maxLength) {
            const ending = "..."
            labelText = labelText.substring(0, maxLength-ending.length) + ending
        }
        

        return (
            <div className={styles.container}>
                <form onSubmit={(e) => {this.onSubmit(e)}}>
                    <button 
                        //disabled="disabled"
                        onClick={this.enterEditMode}
                        type="button" 
                        value={this.props.value}
                        disabled={this.props.disabled}
                        className={`
                            ${styles.button} 
                            ${this.state.editMode && styles.hidden}
                            `}>
                                <img src={iconEdit} alt="Edit" />
                                <span>{labelText}</span>
                    </button>
                    <input type="text" name="lname" id="lname" 
                        className={`
                            ${styles.inputText}
                            ${this.state.editMode && styles.visible}
                        `}
                        ref={ref => this.inputTextEl = ref} 
                        onChange={(e) => {this.onChange(e)}}
                        onBlur={this.exitEditMode}
                        onKeyDown={this.handleKeyDown}
                        value={this.props.value}
                    />
                    
                </form>

            </div>
        )
    }
}

export default NumberEdit
