import React, {Component} from 'react'

import intl from 'react-intl-universal'

import Select from './Select'

import styles from './RoomSurfaces.module.scss'

import ceilingMaterials from '../../data/ceilingMaterials'
import wallMaterials from '../../data/wallMaterials'
import floorMaterials from '../../data/floorMaterials'



class RoomSurfaces extends Component {

    constructor(props) {
        
        super(props)

        this.ceilingMaterialNames = [];
        ceilingMaterials.forEach((material) => {
            this.ceilingMaterialNames.push(
                intl.get(material.name, {
                    alpha: material.absorptionCoefficient
                })
            )
        })

        this.wallMaterialNames = [];
        wallMaterials.forEach((material) => {
            this.wallMaterialNames.push(
                intl.get(material.name, {
                    alpha: material.absorptionCoefficient
                })
            )
        })

        this.floorMaterialNames = [];
        floorMaterials.forEach((material) => {
            this.floorMaterialNames.push(
                intl.get(material.name, {
                    alpha: material.absorptionCoefficient
                })
            )
        })
        
    }

    render() {
    
        return (
            <div className={styles.container}>
                <h3>{intl.get('topNav.surfaces')}</h3>
                <div className={styles.controllerContainer}>
                    <Select 
                        label={intl.get('surfaces.ceiling')}
                        names={this.ceilingMaterialNames}
                        value={this.props.ceilingMaterialIndex}
                        onChange={this.props.onCeilingMaterialChanged}
                    />
                </div>
                <div className={styles.controllerContainer}>
                    <Select 
                        label={intl.get('surfaces.walls')}
                        names={this.wallMaterialNames}
                        value={this.props.wallMaterialIndex}
                        onChange={this.props.onWallMaterialChanged}
                    />
                </div>
                <div className={styles.controllerContainer}>
                    <Select 
                        label={intl.get('surfaces.floor')}
                        names={this.floorMaterialNames}
                        value={this.props.floorMaterialIndex}
                        onChange={this.props.onFloorMaterialChanged}
                    />
                </div>
            </div>
        )
    }
}

export default RoomSurfaces