import React, {Component} from 'react'
import intl from 'react-intl-universal'
import styles from './Reverberation.module.scss'
import products from '../../data/products'
import HorizontalBar from './HorizontalBar'

class Reverberation extends Component {
    constructor(props) {
        super(props)

        this.productNames = []
        for (const product in products) {
            this.productNames.push(product)
        }

    }

    reverberationFor(absorptionArea) {
        const roomVolume = this.props.roomWidth * this.props.roomDepth * this.props.roomHeight
        const reverberationTime = 0.16 * (roomVolume / absorptionArea)
        return reverberationTime
    }

    render() {
        
        const { currentLocale } = intl.getInitOptions()
        

        let reverberationWithoutTextiles = this.reverberationFor(this.props.absorptionAreaForSurfaces)
        let reverberationWithTextiles = this.reverberationFor(this.props.absorptionAreaForSurfaces + this.props.absorptionAreaForTextiles)
        const reverberationRatio = (reverberationWithTextiles / reverberationWithoutTextiles) * 100

        let description = null
        description = <>{intl.get('reverberation.subtitle')}</>
        let formulaSource = null
        if (this.props.showFormulaSource) { 
            formulaSource = <>{intl.getHTML('reverberation.formulaSource')}</>
        }

        
        
        return (

            <div className={styles.container}>
                
                {!this.props.showBar && <>
                    <h3>{intl.get('reverberation.title')}</h3>
                    <div className={styles.labelContainer}>
                        <div className={`${styles.label}`}>
                            {intl.get('reverberation.seconds', {
                                seconds: reverberationWithTextiles.toLocaleString(currentLocale, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            })}
                        </div>
                    </div>
                </>}
                
                {this.props.showBar && 
                
                    <div className={styles.showBar}>

                        <h3>{intl.get('reverberation.title')}</h3>

                        {this.props.showSubtitle && 
                            <p className="subtitle">{description}</p>
                        }
                    
                        <div className={styles.chartContainer}>

                            <HorizontalBar
                                percent={reverberationRatio}
                                labelLeft={intl.get('reverberation.seconds', {
                                    seconds: reverberationWithTextiles.toLocaleString(currentLocale, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })
                                })}
                                labelRight={intl.get('reverberation.seconds', {
                                    seconds: (-(reverberationWithoutTextiles-reverberationWithTextiles)).toLocaleString(currentLocale, {
                                        minimumFractionDigits: 2, 
                                        maximumFractionDigits: 2
                                    })

                                })}
                                description={intl.get('general.withTextiles')}
                            />
                    
                        {this.props.showFormulaSource &&
                            <p className={styles.formulaDescription}>{formulaSource}</p>
                        }
                        
                        </div> 

                    </div>
                
                }
                
            </div>

        )

    }

}

export default Reverberation