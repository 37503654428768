import React, {Component} from 'react'
import intl from 'react-intl-universal'

import Guide from './Guide'

import styles from './Facts.module.scss'
import helpers from '../../utils/helpers'

class Facts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentExpandedAccordionIndex: null
        }

        this.contractAllAccordionsExcept = this.contractAllAccordionsExcept.bind(this)

    }

    contractAllAccordionsExcept(index) {
        this.setState({
            currentExpandedAccordionIndex: index
        })
    }

    render() {

        const glossaryItems = intl.get('glossary.items')
        let guideElementsLeft = []
        let guideElementsRight = []
        
        for (let i=0; i<glossaryItems.length; i+=2) {
            let guides = []
            for (let j=0; j<2; j++) {
                const guideIndex = i+j
                glossaryItems[guideIndex] &&
                    guides.push(
                        <Guide 
                            key={guideIndex}
                            index={guideIndex}
                            title={glossaryItems[guideIndex].title}
                            content={glossaryItems[guideIndex].content}
                            onAccordionExpand={this.contractAllAccordionsExcept}
                            shouldContract={this.state.currentExpandedAccordionIndex === guideIndex ? false : true}
                        />
                )
            }

            guides[0] && guideElementsLeft.push(guides[0])
            guides[1] && guideElementsRight.push(guides[1])

        }

        const reverberationTables = intl.get('reverberationTables')
        let reverberationTableElements = []
        const tableHeaders = 
            <tr>
                <th>{intl.get('reverberationTables.tableHeader')}</th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
            </tr>
    
        reverberationTables.forEach((table, index) => {
            reverberationTableElements.push(
                <React.Fragment key={index}>
                    <h3>{table.title}</h3>
                    <p>{table.description}</p>
                </React.Fragment>
            )
            
            let tableRows = []
            table.items.forEach((item, index) => {
                let values = []
                item.values.forEach((value, index) => {
                    let valueString = "–"
                    if (value) {
                        valueString = helpers.getNumberString(value)
                    }
                    values.push(<td key={`value ${index}`}>{valueString}</td>)
                })
                tableRows.push(
                    <tr key={`tr ${index}`}>
                        <td>{item.description}</td>
                        {values}
                    </tr>
                )
            })
            reverberationTableElements.push(
                <table key={`table ${index}`}>
                    <thead>
                        {tableHeaders}
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            )
            
        })

        return (
            <div className={styles.container} ref={ref => (this.container = ref)}>
                
                <h2>{intl.get('glossary.header')}</h2>

                <div className={styles.glossaryItems}>
                    <div className={styles.glossaryCol}>
                        {guideElementsLeft}
                    </div>
                    <div className={styles.glossaryCol}>
                        {guideElementsRight}
                    </div>
                </div>

                <h2>{intl.get('reverberationTables.header')}</h2>

                <div className={styles.tables}>
                    {reverberationTableElements}
                </div>

            </div>
        )
    }
}

export default Facts