import React, {Component} from 'react'
import styles from './ColorPicker.module.scss'

class ColorPicker extends Component {

    render() {
        
        let cards = []
        if (this.props.images) {
            for (let i=0; i<this.props.images.length; i++) {

                let selectedClass = null
                if (this.props.colorIndex === i) {
                    selectedClass = styles.selected
                }

                if (this.props.colors[i]) {
                    cards.push(
                        <div className={`${styles.card} ${selectedClass}`} onClick={() => this.props.onChange(i)} key={i}>
                            <div className={styles.imgContainer}>
                                <img src={this.props.images[i]} alt="..." />
                            </div>
                            <span className={styles.label}>{this.props.colors[i]}</span>
                        </div>
                    )
                }
            }
        }
            
       
        return (
            <>
            {cards.length > 0 ? 
            <div className={styles.container}>
                {cards}
            </div>
            : null }
            </>
        )

    }

}

export default ColorPicker