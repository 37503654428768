import React, {Component} from 'react'
import intl from 'react-intl-universal'
import products from '../../data/products'
import helpers from '../../utils/helpers'
import styles from './Output.module.scss'
import Room4 from './Room4'
import FieldEdit from '../Lab/FieldEdit'
import Reverberation from './Reverberation'
import Decibel from './Decibel'

class Output extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dBReduction: 0,
            fullWidth: false,
        }
        
        this.productNames = []
        for (const product in products) {
            this.productNames.push(product)
        }
        
        
    }

    absorptionAreaForSurfaces() {
        let absorptionAreaCeiling = 0
        if (this.props.ceilingMaterial) {
            absorptionAreaCeiling = this.props.roomWidth * this.props.roomDepth * this.props.ceilingMaterial.absorptionCoefficient
        }
        
        let absorptionAreaWalls = 0
        if (this.props.wallMaterial) {
            absorptionAreaWalls = (2 * this.props.roomWidth * this.props.roomHeight + 2 * this.props.roomDepth * this.props.roomHeight) * this.props.wallMaterial.absorptionCoefficient
        }
        
        let absorptionAreaFloor = 0
        if (this.props.floorMaterial) {
            absorptionAreaFloor = this.props.roomWidth * this.props.roomDepth * this.props.floorMaterial.absorptionCoefficient
        }

        const absorptionArea = absorptionAreaCeiling + absorptionAreaWalls + absorptionAreaFloor

        return absorptionArea
    }

    absorptionAreaForTextiles() {

        let absorptionArea = 0

        this.props.absorbantRoom.absorbantWalls.forEach(wall => {
            const layers = [wall.layerOne, wall.layerTwo]
            layers.forEach(layer => {
                if (layer.productIndex >= 0) {
                    const layerArea = layer.width * layer.height
                    if (!layer.folded && layer.distance === "10") {
                        absorptionArea += layerArea * products[this.productNames[layer.productIndex]].distance10Flat.absorptionCoefficient
                    }
                    else if (!layer.folded && layer.distance === "15") {
                        absorptionArea += layerArea * products[this.productNames[layer.productIndex]].distance15Flat.absorptionCoefficient
                    }
                    else if (layer.folded && layer.distance === "10") {
                        absorptionArea += layerArea * products[this.productNames[layer.productIndex]].distance10Folded.absorptionCoefficient
                    }
                    else if (layer.folded && layer.distance === "15") {
                        absorptionArea += layerArea * products[this.productNames[layer.productIndex]].distance15Folded.absorptionCoefficient
                    }
                }
            })
        });

        return absorptionArea
    }

    render() {

        const absorptionAreaForSurfaces = this.absorptionAreaForSurfaces()
        const absorptionAreaForTextiles = this.absorptionAreaForTextiles()
        const dBReduction = helpers.noiseLevelReduction(absorptionAreaForSurfaces, absorptionAreaForSurfaces + absorptionAreaForTextiles)
        const storedUser = JSON.parse(sessionStorage.getItem("currentUser")); // Get user from sessionStorage
        const { salesAcoustic } = storedUser; // Gets salesAcoustic from storedUser

        let roomName = this.props.absorbantRoom.name
        if (!roomName || roomName === "") {
            roomName = intl.get('report.interior') + " " + this.props.absorbantRoom.id
        }
      
        if (!salesAcoustic)
        { 
            // If absorbantroom id is 2 or higher, null will be returned an no more rooms will be visible. 
            if (this.props.absorbantRoom.id >= 2) {
                return null; 
            }
        }

        return (
            <div style={{ marginLeft: this.props.marginLeft }} className={`${styles.container} ${this.props.fullWidthActive && styles.fullWidth}`}>
                
                <div className={styles.headerWrapper}>
                    {this.props.absorbantRoom.empty && !this.props.fullWidthActive &&
                        <h2>{intl.get('report.emptyRoom')}</h2>
                    }
                    {!this.props.absorbantRoom.empty && !this.props.fullWidthActive &&
                    <FieldEdit
                        value={roomName}
                        id={this.props.absorbantRoom.id}
                        onChange={this.props.onRoomNameChanged}
                        centered={true}
                        large={true}
                    />
                    }
                    </div>
                
                <Room4
                    onRoomNameChanged={this.props.onRoomNameChanged}
                    roomWidth={this.props.roomWidth}
                    roomDepth={this.props.roomDepth}
                    roomHeight={this.props.roomHeight}
                    ceilingMaterial = {this.props.ceilingMaterial}
                    wallMaterial = {this.props.wallMaterial}
                    floorMaterial = {this.props.floorMaterial}
                    absorbantRooms = {this.props.absorbantRooms}
                    absorbantRoom = {this.props.absorbantRoom}
                    activeAbsorbantRoomIndex = {this.props.activeAbsorbantRoomIndex}
                    onActivateAbsorbantWall = {this.props.onActivateAbsorbantWall}
                    inputpaneActive = {this.props.inputpaneActive}
                    atLeastOneProductActive = {this.props.atLeastOneProductActive}
                    fullWidthActive = {this.props.fullWidthActive}
                    openFullWidth = {this.props.openFullWidth}
                    fullWidthStartTime = {this.props.fullWidthStartTime}
                    fullWidthMilliseconds = {1000}
                    dBReduction = {dBReduction}
                    startedPlaying = {this.props.startedPlaying}
                    shouldPause = {this.props.shouldPause}
                />

                {(this.props.atLeastOneProductActive(this.props.absorbantRoom.id) 
                    || this.props.absorbantRoom.id) < this.props.absorbantRooms.length-1 
                    &&

                        <>  
                            <div className={styles.charts}>
                                
                                <div className={styles.leftChart}>
                                    <Reverberation
                                        roomWidth={this.props.roomWidth}
                                        roomDepth={this.props.roomDepth}
                                        roomHeight={this.props.roomHeight}
                                        absorptionAreaForSurfaces = {absorptionAreaForSurfaces}
                                        absorptionAreaForTextiles = {absorptionAreaForTextiles}
                                        showBar = {!this.props.absorbantRoom.empty}
                                    />
                                </div>

                                {!this.props.absorbantRoom.empty &&
                                    <div className={styles.rightChart}>
                                        <Decibel
                                            dBReduction = {dBReduction}
                                            show = {true}
                                        />
                                    </div>
                                }
                                
                            </div>
                        </>
                }
            </div>
        )
    }
}

export default Output

