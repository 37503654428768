import React, {Component} from 'react'
import intl from 'react-intl-universal'
import Button from '../Lab/Button'
import Audio from '../Output/Audio'
import LightPDFGenerator from './PDF/LightPDFGenerator'
import PDFGenerator from './PDF/PDFGenerator'
import HorizontalBar from '../Output/HorizontalBar'
import helpers from '../../utils/helpers'
import styles from './Report-2.module.scss'
import iconPdf from '../Lab/icon-pdf-black.svg'

class Report extends Component {

    constructor(props) {
        super(props)

        this.state = {
            shouldGeneratePdf: false,
        }

        this.onFilePdf = this.onFilePdf.bind(this)
        this.onFilePdfDone = this.onFilePdfDone.bind(this)

        this.pdfGenerator = new PDFGenerator()
        this.lightPDFGenerator = new LightPDFGenerator();
    }


    getTextileImage(layer) {
        if (layer.product && layer.product.images) {
          if (layer.colorIndex < 0) {
            return layer.product.imagesSmall[layer.product.images.length - 1]
          }
          else  {
            return layer.product.imagesSmall[layer.colorIndex]
          }
        }
        return false
      }

      generatePdf() {

        this.pdfGenerator.setParams(
            this.props.absorbantRooms,
            this.props.roomWidth,
            this.props.roomHeight,
            this.props.roomDepth,
            this.props.ceilingMaterial,
            this.props.wallMaterial,
            this.props.floorMaterial
        )
        this.pdfGenerator.start()
    }

    generateLightPdf() {
        this.lightPDFGenerator.setParams(
            this.props.absorbantRooms,
            this.props.roomWidth,
            this.props.roomHeight,
            this.props.roomDepth,
            this.props.ceilingMaterial,
            this.props.wallMaterial,
            this.props.floorMaterial
        )
        this.lightPDFGenerator.start()
    }

    onFilePdf() {
        this.setState({
            shouldGeneratePdf: true
        })
    }

    onFilePdfDone() {
        this.setState({
            shouldGeneratePdf: false
        })
    }

    componentWillMount() {

    }

    componentDidUpdate() {

    }

    render() {

        const {currentLocale} = intl.getInitOptions()



        let wallRows = []
        let floorRows = []
        let ceilingRows = []
        let absorptionAreaRows = []
        let audioPlayRows = []
        let reverberationRows = []
        let decibelRows = []

        const wallIterator = this.props.absorbantRooms[0].absorbantWalls

        wallIterator.forEach((wall, wallIndex) => {

            let wallWidth = this.props.roomWidth
            let wallHeight = this.props.roomHeight
            if (wall.description === "right"
                || wall.description === "left") {
                    wallWidth = this.props.roomDepth
            }

            //const wallArea = wallWidth * wallHeight

            wallRows.push(
                <CardHeader
                    key={`wallrow ${wallRows.length-1}`}
                    header = {intl.get('textiles.wall', {name: wall.name})}
                    dimensions = {<>{helpers.getNumberString(wallWidth, 2)} x {helpers.getNumberString(wallHeight, 2)} m</>}
                    area = {intl.get('general.squareMeter', {
                        area: helpers.getNumberString(wallWidth * wallHeight, 2)
                    })}
                />
            )

            let cards = []

            let rowHasProduct = false
            this.props.absorbantRooms.forEach((room) => {
                if (room.absorbantWalls[wallIndex].layerOne.productIndex >= 0) {
                    rowHasProduct = true
                }
            })

            this.props.absorbantRooms.forEach((room) => {
                if (room.id < 2 || this.props.atLeastOneProductActive(room.id)) {
                    const wall = room.absorbantWalls[wallIndex]
                    let header = intl.get('report.emptyRoom')
                    if (!room.empty) {
                        header = room.name
                        if (!header || header.length === 0) {
                            header = intl.get('report.interior') + " " + room.id
                        }
                    }

                    const layer = wall.layerOne
                    let material = intl.get(this.props.wallMaterial.name + '.name')
                    let color = null
                    let alpha = this.props.wallMaterial.absorptionCoefficient
                    let shapeIndicator = null

                    const productVariant = helpers.productVariantForLayer(layer)
                    let absorptionClass = null
                    let layerWidth = null
                    let layerHeight = null
                    let implementation = null
                    let image = null
                    let wallArea = this.props.roomWidth * this.props.roomHeight
                    if (wall.description === "right" ||  wall.description === "left") {
                        wallArea = this.props.roomDepth * this.props.roomHeight
                    }
                    let absorptionArea = wallArea * this.props.wallMaterial.absorptionCoefficient
                    if (layer.productIndex >= 0) {
                        material = layer.product.name
                        color = layer.product.colors[layer.colorIndex]
                        if (color) {
                            material += ", " + color
                        }
                        alpha = productVariant.absorptionCoefficient
                        if (productVariant.shapeIndicator) {
                            shapeIndicator = productVariant.shapeIndicator
                        }
                        absorptionClass = productVariant.absorptionClass
                        layerWidth = <>{intl.get('general.width')}: {helpers.getNumberString(layer.width, 2)} m</>
                        layerHeight = <>{intl.get('general.height')}: {helpers.getNumberString(layer.height, 2)} m</>
                        layer.folded ?
                            implementation = intl.get('report.implementationFolded')
                            :
                            implementation = intl.get('report.implementationFlat')
                        implementation += ", "
                        implementation += intl.get('report.implementationDistance', {distance: layer.distance})
                        image = this.getTextileImage(layer)
                        absorptionArea = helpers.absorptionAreaForLayer(layer)
                    }
                    alpha = helpers.getNumberString(parseFloat(alpha), 2)
                    alpha = 'αw = ' + alpha
                    if (shapeIndicator) {
                        alpha += ' (' + productVariant.shapeIndicator + ')'
                    }
                    alpha = <>{alpha}</>

                    if (absorptionClass) {
                        absorptionClass = <>{intl.get('report.class')} {absorptionClass}</>
                    }
                    absorptionArea = intl.get('general.squareMeter', {area: helpers.getNumberString(absorptionArea, 2)})

                    cards.push(

                        <Card
                            key={`card ${cards.length-1}`}
                            rowHasProduct={rowHasProduct}
                            header={header}
                            material={material}
                            color={color}
                            absorptionClass={absorptionClass}
                            alpha={alpha}
                            wallMaterial = {this.props.wallMaterial}
                            productVariant = {helpers.productVariantForLayer(layer)}
                            layerWidth = {layerWidth}
                            layerHeight = {layerHeight}
                            implementation = {implementation}
                            image = {image}
                            absorptionArea = {absorptionArea}
                        />

                    )
                }
            })

            wallRows.push(
                <div className={styles.cardsContainer} key={`wallrowdiv ${wallRows.length-1}`}>
                    {cards}
                </div>
            )


        })

        floorRows.push(
            <CardHeader
                key={`cardheader ${floorRows.length-1}`}
                header = {intl.get('surfaces.floor')}
                dimensions = {<>{helpers.getNumberString(this.props.roomWidth, 2)} x {helpers.getNumberString(this.props.roomDepth, 2)} m</>}
                area = {intl.get('general.squareMeter', {
                    area: helpers.getNumberString(this.props.roomWidth * this.props.roomDepth, 2)
                })}
            />
        )

        ceilingRows.push(
            <CardHeader
                key={`cardheader ${ceilingRows.length-1}`}
                header = {intl.get('surfaces.ceiling')}
                dimensions = {<>{helpers.getNumberString(this.props.roomWidth, 2)} x {helpers.getNumberString(this.props.roomDepth, 2)} m</>}
                area = {intl.get('general.squareMeter', {
                    area: helpers.getNumberString(this.props.roomWidth * this.props.roomDepth, 2)
                })}
            />
        )

        absorptionAreaRows.push(
            <CardHeader
                key={`cardheader ${absorptionAreaRows.length-1}`}
                header = {intl.get('report.totalAbsorptionArea')}
                description = {intl.get('report.totalAbsorptionAreaDescription')}
            />
        )

        audioPlayRows.push(
            <CardHeader
                key={`cardheader ${audioPlayRows.length-1}`}
                header = {intl.get('audio.audio')}
                description = {intl.get('audio.audioDescription')}
            />
        )

        reverberationRows.push(
            <CardHeader
                key={`cardheader ${reverberationRows.length-1}`}
                header = {intl.get('reverberation.title')}
                description = {intl.get('report.reverberationDescription')}
            />
        )

        decibelRows.push(
            <CardHeader
                key={`cardheader ${decibelRows.length-1}`}
                header = {intl.get('decibel.relativeLowering')}
                description = {intl.get('report.audioGainDescription')}
            />
        )

        let floorCards = []
        let ceilingCards = []
        let absorptionAreaCards = []
        let audioPlayCards = []
        let reverberationCards = []
        let decibelCards = []

        this.props.absorbantRooms.forEach((room) => {

            if (room.id < 2 || this.props.atLeastOneProductActive(room.id)) {

                let header = intl.get('report.emptyRoom')
                if (!room.empty) {
                    header = room.name
                    if (!header || header.length === 0) {
                        header = intl.get('report.interior') + " " + room.id
                    }
                }

                let area = this.props.roomWidth * this.props.roomDepth

                let floorAbsorptionArea = area * this.props.floorMaterial.absorptionCoefficient
                let floorAbsorptionAreaString = intl.get('general.squareMeter', {area: helpers.getNumberString(floorAbsorptionArea, 2)})
                let floorAlpha = this.props.floorMaterial.absorptionCoefficient
                floorAlpha = 'αw = ' + helpers.getNumberString(parseFloat(floorAlpha), 2)
                floorAlpha = <>{floorAlpha} &alpha;w</>

                let ceilingAbsorptionArea = area * this.props.ceilingMaterial.absorptionCoefficient
                let ceilingAbsorptionAreaString = intl.get('general.squareMeter', {area: helpers.getNumberString(ceilingAbsorptionArea, 2)})
                let ceilingAlpha = this.props.ceilingMaterial.absorptionCoefficient
                ceilingAlpha = 'αw = ' + helpers.getNumberString(parseFloat(ceilingAlpha), 2)
                ceilingAlpha = <>{ceilingAlpha} &alpha;w</>

                floorCards.push(
                    <Card
                        key={`floorcard ${floorCards.length-1}`}
                        header={header}
                        material={intl.get(this.props.floorMaterial.name + '.name')}
                        alpha={floorAlpha}
                        absorptionArea = {floorAbsorptionAreaString}
                    />
                )

                ceilingCards.push(
                    <Card
                        key={`celingcard ${ceilingCards.length-1}`}
                        header={header}
                        material={intl.get(this.props.ceilingMaterial.name + '.name')}
                        alpha={ceilingAlpha}
                        absorptionArea = {ceilingAbsorptionAreaString}
                    />
                )

                const totalWallArea = 2 * this.props.roomWidth * this.props.roomHeight + 2 * this.props.roomDepth * this.props.roomHeight
                const totalArea = 2 * area + totalWallArea


                const wallAbsorptionArea = totalWallArea * this.props.wallMaterial.absorptionCoefficient

                const textilesAbsorptionArea = helpers.absorptionAreaForTextiles(room.absorbantWalls)

                let totalAbsorptionArea = wallAbsorptionArea + ceilingAbsorptionArea + floorAbsorptionArea + textilesAbsorptionArea


                const totalAbsorptionAreaText = intl.get("general.squareMeter", {
                    area: helpers.getNumberString(totalAbsorptionArea, 2)
                })

                const totalAreaText = intl.get("general.squareMeter", {
                    area: helpers.getNumberString(totalArea, 2)
                })

                const absorptionBar = <HorizontalBar
                    percent={(totalAbsorptionArea / totalArea) * 100}
                    labelLeft={totalAbsorptionAreaText}
                    labelRight={totalAreaText}
                    highlight={"left"}
                    labelPosition={"top"}
                    description={"description"}
                />

                absorptionAreaCards.push(
                    <Card
                        key={`absorptionareacard ${absorptionAreaCards.length-1}`}
                        header={header}
                        bar = {absorptionBar}
                    />
                )


                const roomVolume = area * this.props.roomHeight
                const emptyRoomAbsorptionArea = wallAbsorptionArea + ceilingAbsorptionArea + floorAbsorptionArea
                const emptyRoomReverberation = helpers.reverberationFor(emptyRoomAbsorptionArea, roomVolume)
                const totalReverberation = helpers.reverberationFor(totalAbsorptionArea, roomVolume)
                const dBReduction = helpers.noiseLevelReduction(emptyRoomAbsorptionArea, totalAbsorptionArea)

                const audio = <Audio
                    dBReduction = {dBReduction}
                    startedPlaying = {this.props.startedPlaying}
                    index = {room.id}
                    shouldPause = {this.props.shouldPause[room.id]}
                    compact = {true}
                />



                audioPlayCards.push(
                    <Card
                        key={`audioplaycard ${audioPlayCards.length-1}`}
                        header={header}
                        audio = {audio}
                        padding = {0}
                    />
                )


                let reverberationBarLabelRight = null
                if (emptyRoomReverberation !== totalReverberation) {
                    reverberationBarLabelRight =
                        intl.get('reverberation.seconds', {
                            seconds: (-(emptyRoomReverberation-totalReverberation)).toLocaleString(currentLocale, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })
                        })

                }

                const reverberationBar = <HorizontalBar
                    percent={(totalReverberation / emptyRoomReverberation) * 100}
                    labelLeft={intl.get('reverberation.seconds', {
                        seconds: totalReverberation.toLocaleString(currentLocale, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })
                    })}
                    labelRight={reverberationBarLabelRight}
                    highlight={"right"}
                    labelPosition={"top"}
                    description={"description"}
                />

                reverberationCards.push(
                    <Card
                        key={`reverberationcard ${reverberationCards.length-1}`}
                        header={header}
                        bar = {reverberationBar}
                    />
                )


                const audioGain = helpers.dBReductionToGain(dBReduction)


                const dBBar = <HorizontalBar
                    percent={audioGain * 100}
                    labelLeft={null}
                    labelRight={intl.get('decibel.relativeDB', {
                        dB: dBReduction.toLocaleString(currentLocale, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })
                    })}
                    highlight={"right"}
                    labelPosition={"top"}
                    description={"description"}
                />

                decibelCards.push(
                    <Card
                        key={`decibelcard ${decibelCards.length-1}`}
                        header={header}
                        bar = {dBBar}
                    />
                )

            }
        })

        floorRows.push(
            <div className={styles.cardsContainer}  key={`floorRows ${floorRows.length-1}`}>
                {floorCards}
            </div>
        )

        ceilingRows.push(
            <div className={styles.cardsContainer}  key={`ceilingRows ${ceilingRows.length-1}`}>
                {ceilingCards}
            </div>
        )

        absorptionAreaRows.push(
            <div className={styles.cardsContainer}  key={`absorptionAreaRows ${absorptionAreaRows.length-1}`}>
                {absorptionAreaCards}
            </div>
        )

        audioPlayRows.push(
            <div className={styles.cardsContainer}  key={`audioPlayRows ${audioPlayRows.length-1}`}>
                {audioPlayCards}
            </div>
        )

        reverberationRows.push(
            <div className={styles.cardsContainer}  key={`reverberationRows ${reverberationRows.length-1}`}>
                {reverberationCards}
            </div>
        )

        decibelRows.push(
            <div className={styles.cardsContainer}  key={`decibelRows ${decibelRows.length-1}`}>
                {decibelCards}
            </div>
        )

        return(

            <div className={styles.container} ref={(ref) => this.containerRef = ref}>

                <div className={styles.headerContainer}>
                    <div className={styles.col}>
                        <h2>{intl.get('report.report')}</h2>
                        <p>{intl.get('report.description')}</p>
                    </div>
                </div>
                <div style={{marginTop:'20px'}}>
                    <Button
                        rounded
                        strongBorders
                        iconFirst
                        label={intl.get('report.generatePdf')}
                        icon={<img src={iconPdf} alt="iconPdf" />}
                        onClick={() => this.generatePdf()}
                        className={styles.button}
                        style={{marginRight:'10px'}}
                    />
                    <Button
                        rounded
                        strongBorders
                        iconFirst
                        label={intl.get('report.generateLightPdf')}
                        icon={<img src={iconPdf} alt="iconPdf" />}
                        onClick={() => this.generateLightPdf()}
                        className={styles.button}
                    />
                </div>

                {wallRows}

                {floorRows}

                {ceilingRows}

                {absorptionAreaRows}

                {audioPlayRows}

                {reverberationRows}

                {decibelRows}

            </div>

        )

    }

}

export default Report



class CardHeader extends Component {
    render() {

        return (

            <div className={styles.cardsHeader}>

                <div className={styles.cardsHeaderLeft}>
                    <h3>{this.props.header}</h3>

                    {this.props.description &&
                        <p>{this.props.description}</p>
                    }

                </div>

                {(this.props.dimensions || this.props.area) &&
                    <div className={styles.cardsHeaderRight}>
                        {this.props.dimensions &&
                            <span>{this.props.dimensions}</span>
                        }
                        {this.props.area &&
                            <span>{this.props.area}</span>
                        }
                    </div>
                }

            </div>

        )
    }
}


class Card extends Component {
   
    render() {

        return (

            <div className={styles.card}>
                <div className={styles.cardWrapper}>

                    <div className={styles.cardHeader}>
                        <h4>{this.props.header}</h4>
                    </div>

                    <div className={`${styles.cardContent} ${this.props.padding === 0 && styles.noPadding}`}>

                        {this.props.material &&
                            <div className={`${styles.cardContentRow}`}>
                                <div className={styles.cardContentCol}>
                                    <p><span className={styles.materialName}>{this.props.material}</span>
                                        {this.props.absorptionClass &&
                                            <>
                                            <br/>{this.props.absorptionClass}
                                            </>
                                        }
                                        <br/>
                                        {this.props.alpha}
                                    </p>
                                </div>
                                {this.props.image &&
                                    <div className={`${styles.cardContentCol} ${styles.right}`}>
                                        <img src={this.props.image} alt="icon" />
                                    </div>
                                }
                            </div>
                        }

                        {this.props.productVariant &&
                            <div className={styles.cardContentRow}>
                                <div className={styles.cardContentCol}>
                                    <p>
                                        {this.props.layerWidth}
                                        <br/>
                                        {this.props.layerHeight}
                                        <br/>
                                        {this.props.implementation}
                                    </p>
                                </div>
                            </div>
                        }

                        {this.props.absorptionArea &&
                        <div className={`${styles.cardContentRow} ${this.props.rowHasProduct && !this.props.productVariant && styles.stickToBottom}`}>
                            <div className={styles.cardContentCol}>
                                <p>
                                    <span className={styles.label}>{this.props.absorptionArea}</span>
                                    <br/>
                                    {intl.get('report.absorptionArea')}
                                </p>
                            </div>
                        </div>
                        }

                        {this.props.bar &&
                        <div className={styles.cardContentRow}>
                            <div className={styles.cardContentCol}>
                                {this.props.bar}
                            </div>
                        </div>
                        }

                        {this.props.audio &&
                        <div className={styles.cardContentRow}>
                            <div className={`${styles.cardContentCol} ${styles.audio}`}>
                                {this.props.audio}
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>

        )
    }
}


